import React, { useState } from "react";
import {
  Grid,
  FormControl,
  Divider,
  Button,
  Box,
  Paper,
  DialogContentText,
  TextField,
  Checkbox as MUICheckbox,
} from "@mui/material";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { pt } from "yup-locale-pt";
import {
  CamposProcessoInicial,
  DialogoConfirmacao,
  DialogoConfirmacaoSimples,
  FileField,
  FileListField,
  Checkbox,
  Autocomplete,
  Timestamps,
} from "../../components";
import api from "../../services/api";
import { listaEnergeticos } from "../../static";
import { useStoreState, useStoreActions } from "easy-peasy";
import { useNavigate } from "react-router-dom";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "./Interesse.module.css";
yup.setLocale(pt);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function checkValueAgainstConstraint(value, constraintFunction) {
  if (value === undefined || value == null || value === "") {
    return false;
  } else if (typeof value === "number") {
    return constraintFunction(value);
  } else {
    return constraintFunction(
      Number.parseFloat(value.replaceAll(".", "").replaceAll(",", "."))
    );
  }
}

const Interesse = () => {
  let navigate = useNavigate();
  let currProcesso = useStoreState((state) => state.processo.current);
  let selectProcesso = useStoreActions((actions) => actions.processo.select);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const schema = yup
    .object({
      cnpj: yup.string().label("CNPJ").cnpj().required(),
      razaoSocial: yup.string().required().label("Razão social"),
      valMedioFatEnergia: yup
        .number()
        .positive()
        .float()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .label("Valor médio da fatura de todos os energéticos"),
      numFuncs: yup
        .number()
        .positive()
        .integer()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .label("Número de funcionários"),
      setorAtuacaoId: yup
        .number()
        .required()
        .nullable()
        .positive()
        .integer()
        .label("Setor de atuação"),
      cep: yup.string().required().label("CEP"),
      rua: yup.string().required().label("Rua"),
      numero: yup.string().required().label("Número"),
      complemento: yup.string().nullable().label("Complemento"),
      bairro: yup.string().required().label("Bairro"),
      cidade: yup.string().required().label("Cidade"),
      estado: yup.string().required().label("Estado"),
      regiaoId: yup
        .number()
        .positive()
        .integer()
        .nullable()
        .required()
        .label("Região"),
      nome: yup.string().required().label("Nome completo"),
      telefone: yup.string().required().label("Telefone de contato"),
      comoSoubeProgramaId: yup
        .number()
        .required()
        .nullable()
        .positive()
        .integer()
        .label("Como sua empresa soube do Programa?"),
      email: yup.string().email().required().label("Email"),
      dreId: yup.number().nullable().label("DRE mais recente"),
      balancoId: yup.number().nullable().label("Balanço mais recente"),
      docsTecnicosField: yup
        .array()
        .label("Arraste documentos técnicos aqui ou clique"),
      faturasEnergiaField: yup
        .array()
        .label("Arraste 12 últimas faturas de energia elétrica"),
      energeticos: yup.array().nullable().label("Energéticos"),
      faturasEnergeticosField: yup
        .array()
        .label("Arraste as faturas dos energéticos"),
      cartaoCnpjId: yup
        .number()
        .positive()
        .nullable()
        .label("Cartão do CNPJ (emissão máxima de 30 dias)"),
      certNegInssId: yup
        .number()
        .positive()
        .nullable()
        .label("Certidão negativa de débito expedida pelo INSS"),
      certRegFgtsId: yup
        .number()
        .positive()
        .nullable()
        .label("Certificado de regularidade do FGTS"),
      certNegDebtTrabId: yup
        .number()
        .positive()
        .nullable()
        .label("Certidão negativa de débitos trabalhistas"),
      certNegDebtEstId: yup
        .number()
        .positive()
        .nullable()
        .label("Certidão negativa de débitos estaduais"),
      aprovaDadosInseridos: yup.boolean().label("Aprovar Dados Inseridos"),
    })
    .required();

  const defaultValues = {
    cnpj: "",
    razaoSocial: "",
    numFuncs: "",
    nome: "",
    telefone: "",
    email: "",
    estado: "",
    dreId: null,
    balancoId: null,
    cartaoCnpjId: null,
    certNegInssId: null,
    certRegFgtsId: null,
    certNegDebtTrabId: null,
    certNegDebtEstId: null,
    regimefuncionamento: ["seg", "ter"],
  };

  const useFormRef = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  const {
    watch,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { isDirty, isSubmitting },
  } = useFormRef;

  const [aprovando, setAprovando] = React.useState([]);
  const [confirmacaoMsg, setConfirmacaoMsg] = React.useState([]);

  const watchNumFuncs = watch("numFuncs", "");
  const watchValMedioFatEnergia = watch("valMedioFatEnergia", "");
  const watchAprovaDadosInseridos = watch("aprovaDadosInseridos", false);
  const watchEstado = watch("estado");

  const getErrorsAderencia = () => {
    let erros = [];
    if (
      !checkValueAgainstConstraint(
        getValues("numFuncs"),
        (value) => value <= 499
      )
    ) {
      erros.push("- Número de empregados precisa ser até 499.");
    }
    // if (
    //   !checkValueAgainstConstraint(
    //     getValues("fatMedio"),
    //     (value) => value <= 300000000
    //   )
    // ) {
    //   erros.push("- Faturamento precisa ser até R$ 300.000.000,00.");
    // }
    if (
      !checkValueAgainstConstraint(
        getValues("valMedioFatEnergia"),
        (value) => value >= 10000
      )
    ) {
      erros.push("- Gastos com energia precisa ser maior que R$ 10.000,00.");
    }
    if (erros.length > 0) {
      return [
        "Recusado automaticamente pelo sistema por não atingir os seguintes critérios: ",
        ...erros,
      ].join("\n");
    } else {
      return null;
    }
  };

  const getPendenciasPreenchimento = () => {
    let erros = [];
    if (!getValues("dreId")) {
      erros.push("Inclusão do DRE mais recente");
    }
    if (!getValues("balancoId")) {
      erros.push("Inclusão do balanço mais recente");
    }
    if (!getValues("cartaoCnpjId")) {
      erros.push("Inclusão do cartão do CNPJ");
    }
    if (!getValues("certNegInssId")) {
      erros.push("Inclusão da certidão negativa de débito expedida pelo INSS");
    }
    if (!getValues("certRegFgtsId")) {
      erros.push("Inclusão do certificado de regularidade do FGTS");
    }
    if (!getValues("certNegDebtTrabId")) {
      erros.push("Inclusão da certidão negativa de débitos trabalhistas");
    }
    if (!getValues("certNegDebtEstId")) {
      erros.push("Inclusão da certidão negativa de débitos estaduais");
    }
    if (
      !getValues("faturasEnergia") &&
      (!getValues("faturasEnergiaField") ||
        getValues("faturasEnergiaField").length === 0)
    ) {
      erros.push("Inclusão das últimas 12 faturas de energia elétrica");
    }

    if (
      !getValues("faturasEnergeticos") ||
      (!getValues("faturasEnergeticosField") &&
        getValues("faturasEnergeticosField").length === 0)
    ) {
      erros.push("Inclusão das faturas dos energéticos");
    }
    if (
      !getValues("docsTecnicos") ||
      (!getValues("docsTecnicosField") &&
        getValues("docsTecnicosField").length === 0)
    ) {
      erros.push("Inclusão dos documentos técnicos");
    }
    if (erros.length > 0) {
      return [
        "Aprovado automaticamente pelo sistema com as seguintes pendências: ",
        ...erros,
      ].join("\n");
    } else {
      return null;
    }
  };

  const verificaPreenchimento = () => {
    let erros = [];
    if (!getValues("dreId")) erros.push("Inclusão do DRE mais recente");
    if (!getValues("balancoId")) erros.push("Inclusão do balanço mais recente");
    if (erros.length > 0)
      return (
        <React.Fragment>
          <DialogContentText>
            Ainda não foram realizadas as seguintes ações:
          </DialogContentText>
          <ul>
            {erros.map((erro, index) => (
              <li key={index}>
                <DialogContentText>{erro}</DialogContentText>
              </li>
            ))}
          </ul>
        </React.Fragment>
      );
    return "";
  };

  const aprova = (data) => {
    setAprovando(true);
    setConfirmacaoMsg(
      <React.Fragment>
        {verificaPreenchimento()}
        <DialogContentText>
          Esta ação não pode ser desfeita. Tem certeza que deseja <b>aprovar</b>{" "}
          o início?
        </DialogContentText>
      </React.Fragment>
    );
    setOpenConfirmacao(true);
  };

  const recusa = () => {
    setAprovando(false);
    setConfirmacaoMsg(
      <React.Fragment>
        Esta ação não pode ser desfeita. Tem certeza que deseja <b>reprovar</b>{" "}
        o início?
      </React.Fragment>
    );
    setOpenConfirmacao(true);
  };

  const desfazerRecusa = () => {
    setOpenConfirmaDesfRecusa(true);
  };

  const salva = async (data) => {
    startLoading();
    try {
      if (data.aprovaDadosInseridos && "interesse" === data.estagio) {
        let errosAderencia = getErrorsAderencia();
        if (errosAderencia !== null) {
          data.observacoes = errosAderencia;
          data.estagio = "inicioReprovado";
        } else {
          let pendenciasPreenchimento = getPendenciasPreenchimento();
          if (pendenciasPreenchimento !== null) {
            data.observacoes = pendenciasPreenchimento;
          }
          data.estagio = "inicioAprovado";
        }
      }
      let processoData;

      if (data.id) {
        processoData = (await api.http.put("/processos/" + data.id, data)).data;
        reset(processoData);
        selectProcesso(processoData);
        stopLoading({ severity: "success" });
      } else {
        processoData = (await api.http.post("/processos", data)).data;
        navigate("/processo/" + processoData.id);
        stopLoading({ severity: "success" });
      }
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const cancela = () => {
    reset();
  };

  const [openConfirmacao, setOpenConfirmacao] = React.useState(false);

  const onSimConfirma = async (data) => {
    setOpenConfirmacao(false);
    const confirmaData = {
      ...data,
      estagio: aprovando ? "inicioAprovado" : "inicioReprovado",
    };
    startLoading();
    try {
      selectProcesso(
        (await api.http.put("/processos/" + currProcesso.id, confirmaData)).data
      );
      stopLoading({ severity: "success" });
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };
  const onNaoConfirma = () => {
    setOpenConfirmacao(false);
  };

  const [openConfirmaDesfRecusa, setOpenConfirmaDesfRecusa] =
    React.useState(false);

  const onConfirmaDesfRecusa = async (data) => {
    setOpenConfirmaDesfRecusa(false);
    const confirmaData = {
      estagio: "interesse",
    };
    startLoading();
    try {
      selectProcesso(
        (await api.http.put("/processos/" + currProcesso.id, confirmaData)).data
      );
      stopLoading({ severity: "success" });
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const onNaoConfirmaDesfRecusa = () => {
    setOpenConfirmaDesfRecusa(false);
  };

  let user = useStoreState((state) => state.auth.user);

  const disabled = () => {
    return (
      (currProcesso &&
        currProcesso.estagio != "interesse" &&
        user.roleId != "superAdmin") ||
      ![
        "superAdmin",
        "consultorEE",
        "validadorIndSENAI",
        "gestorSENAI",
      ].includes(user.roleId)
    );
  };

  React.useEffect(() => {
    if (currProcesso) {
      reset(currProcesso);
      setValue(
        "aprovaDadosInseridos",
        currProcesso.estagio === "interesse" ? false : true
      );
    } else reset(defaultValues);
  }, [currProcesso]);

  const actionButtons = () => {
    if (
      isDirty ||
      !currProcesso ||
      !["inicioAprovado", "inicioReprovado"].includes(currProcesso.estagio)
    ) {
      return (
        <React.Fragment>
          <Button
            variant="outlined"
            size="large"
            sx={{ mr: 2 }}
            onClick={cancela}
            disabled={!isDirty || isSubmitting}
          >
            CANCELAR
          </Button>
          <Button
            variant="contained"
            size="large"
            onClick={handleSubmit(salva)}
            disabled={!isDirty || isSubmitting}
          >
            SALVAR
          </Button>
        </React.Fragment>
      );
    } else if (
      user.roleId == "superAdmin" &&
      currProcesso.estagio == "inicioReprovado"
    ) {
      return (
        <React.Fragment>
          <Button
            variant="outlined"
            size="large"
            sx={{ mr: 2 }}
            onClick={desfazerRecusa}
          >
            DESFAZER PROCESSO RECUSADO
          </Button>
        </React.Fragment>
      );
      // } else {
      //   return (
      //     <React.Fragment>
      //       <Button
      //         variant="outlined"
      //         size="large"
      //         sx={{ mr: 2 }}
      //         onClick={recusa}
      //       >
      //         RECUSAR INÍCIO
      //       </Button>
      //       <Button variant="contained" size="large" onClick={aprova}>
      //         APROVAR INÍCIO
      //       </Button>
      //     </React.Fragment>
      //   );
      // }
    }
  };

  return (
    <Paper
      variant="outlined"
      className="center-box"
      sx={{
        textAlign: "center",
        px: 4,
        pt: 2,
        pb: 3,
        maxWidth: "md",
      }}
    >
      <DialogoConfirmacao
        title="Atenção"
        open={openConfirmacao}
        onSim={onSimConfirma}
        onNao={onNaoConfirma}
        criteriosUrl="https://venidera.sharepoint.com/:w:/s/PotencializEE/ERQo273VKJdCnNs83V21S-4Bjc0hXA9OwW8e2BEpi25Mcw?e=SGwM8y"
      >
        {confirmacaoMsg}
      </DialogoConfirmacao>
      <DialogoConfirmacaoSimples
        title="Atenção"
        open={openConfirmaDesfRecusa}
        onSim={onConfirmaDesfRecusa}
        onNao={onNaoConfirmaDesfRecusa}
      >
        Tem certeza que deseja desfazer recusa do início?
      </DialogoConfirmacaoSimples>
      <form onSubmit={handleSubmit(aprova)}>
        <CamposProcessoInicial
          useFormRef={useFormRef}
          schema={schema}
          disabled={disabled()}
          processo={currProcesso}
          showRegiao={true}
        />

        <Divider>
          <h3>Demonstrativos financeiros</h3>
        </Divider>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl sx={{ my: 2 }} fullWidth>
              <FileField
                field="dreId"
                disabled={disabled()}
                fileData={currProcesso?.dre}
                useFormRef={useFormRef}
                schema={schema}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ my: 2 }} fullWidth>
              <FileField
                field="balancoId"
                disabled={disabled()}
                fileData={currProcesso?.balanco}
                useFormRef={useFormRef}
                schema={schema}
              />
            </FormControl>
          </Grid>
        </Grid>

        <h4>Documentos técnicos</h4>
        <FileListField
          field="docsTecnicos"
          disabled={disabled()}
          useFormRef={useFormRef}
          schema={schema}
          initialFiles={currProcesso?.docsTecnicos}
        />

        <h4>Documentos financeiros</h4>
        <FileListField
          field="faturasEnergia"
          disabled={disabled()}
          useFormRef={useFormRef}
          schema={schema}
          initialFiles={currProcesso?.faturasEnergia}
        />

        <FormControl sx={{ my: 2 }} fullWidth>
          <Autocomplete
            multiple
            disableCloseOnSelect
            field="energeticos"
            disabled={disabled()}
            useFormRef={useFormRef}
            schema={schema}
            options={listaEnergeticos.filter(
              (energetico) => energetico.id != 1
            )}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <MUICheckbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {props.key}
              </li>
            )}
          />
        </FormControl>

        <FileListField
          field="faturasEnergeticos"
          disabled={disabled()}
          useFormRef={useFormRef}
          schema={schema}
          initialFiles={currProcesso?.faturasEnergeticos}
        />

        <Grid container spacing={2}>
          <Grid item xs={4}>
            <FormControl sx={{ my: 2 }} fullWidth>
              <FileField
                field="cartaoCnpjId"
                disabled={disabled()}
                fileData={currProcesso?.cartaoCnpj}
                useFormRef={useFormRef}
                schema={schema}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ my: 2 }} fullWidth>
              <FileField
                field="certNegInssId"
                disabled={disabled()}
                fileData={currProcesso?.certNegInss}
                useFormRef={useFormRef}
                schema={schema}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl sx={{ my: 2 }} fullWidth>
              <FileField
                field="certRegFgtsId"
                disabled={disabled()}
                fileData={currProcesso?.certRegFgts}
                useFormRef={useFormRef}
                schema={schema}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControl sx={{ my: 2 }} fullWidth>
              <FileField
                field="certNegDebtTrabId"
                disabled={disabled()}
                fileData={currProcesso?.certNegDebtTrab}
                useFormRef={useFormRef}
                schema={schema}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl sx={{ my: 2 }} fullWidth>
              <FileField
                field="certNegDebtEstId"
                disabled={disabled()}
                fileData={currProcesso?.certNegDebtEst}
                useFormRef={useFormRef}
                schema={schema}
              />
            </FormControl>
          </Grid>
        </Grid>

        <Divider>
          <h3>Verificação de aderência</h3>
        </Divider>
        <Grid container justifyContent={"center"}>
          <Grid item xs={"auto"}>
            <FormControl sx={{ my: 1 }} fullWidth>
              <Checkbox
                field="aprovaDadosInseridos"
                disabled={currProcesso && currProcesso.estagio !== "interesse"}
                schema={schema}
                useFormRef={useFormRef}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={2} justifyContent={"center"}>
          <Grid item xs={4}>
            <FormControl sx={{ my: 2 }} fullWidth>
              <div className={styles.verificacaoAderenciaContainer}>
                {checkValueAgainstConstraint(
                  watchNumFuncs,
                  (value) => value <= 499
                ) ? (
                  <CheckBoxIcon fontSize="small" color="success" />
                ) : (
                  <CancelIcon fontSize="small" color="error" />
                )}
                <span>Número de Empregados</span>
              </div>
            </FormControl>
          </Grid>
          {/* <Grid item xs={4}>
            <FormControl sx={{ my: 2 }} fullWidth>
              <div className={styles.verificacaoAderenciaContainer}>
                {checkValueAgainstConstraint(
                  watchFatMedio,
                  (value) => value <= 300000000
                ) ? (
                  <CheckBoxIcon fontSize="small" color="success" />
                ) : (
                  <CancelIcon fontSize="small" color="error" />
                )}

                <span>Faturamento</span>
              </div>
            </FormControl>
          </Grid> */}
          <Grid item xs={4}>
            <FormControl sx={{ my: 2 }} fullWidth>
              <div className={styles.verificacaoAderenciaContainer}>
                {checkValueAgainstConstraint(
                  watchValMedioFatEnergia,
                  (value) => value >= 10000
                ) ? (
                  <CheckBoxIcon fontSize="small" color="success" />
                ) : (
                  <CancelIcon fontSize="small" color="error" />
                )}
                <span>Gastos com energia</span>
                <span style={{ fontSize: "12px" }}>({">"} R$ 10k/mês)</span>
              </div>
            </FormControl>
          </Grid>
        </Grid>

        {currProcesso && currProcesso.estagio != "interesse" && (
          <React.Fragment>
            <Divider>
              <h3>
                Observações de{" "}
                {currProcesso.estagio == "inicioReprovado"
                  ? "reprovação"
                  : "aprovação"}
              </h3>
            </Divider>
            <FormControl sx={{ my: 2 }} fullWidth>
              <TextField
                value={currProcesso.observacoes}
                multiline
                rows={4}
                disabled={true}
              />
            </FormControl>
          </React.Fragment>
        )}

        <Timestamps dados={currProcesso} />

        {!disabled() && (
          <Box sx={{ mt: 2, textAlign: "center" }}>{actionButtons()}</Box>
        )}
      </form>
    </Paper>
  );
};

export default Interesse;
