import React from 'react';
import {
    Grid,
    Box,
    Typography,
} from "@mui/material";
import { useStoreState } from "easy-peasy";
import './ModalRelResumo.css';
import { ConstructionOutlined } from '@mui/icons-material';

import { formatCurrency, formatInteger, formatTime } from '../../services/utils';

const RelatorioResultadoDadosGerais = ({ lstQuestionario, lstRespostas, cmpcapaSegmento }) => {

    let currProcesso = useStoreState((state) => state.processo.current);

    // //método para retornar o valor para o relatorio de um select option list
    const getValueFromOptionsList = (questionName, answerValue) => {
        let valueFromOptions = null;

        lstQuestionario.steps.forEach((step) => {
            step.groups.forEach((group) => {
                if (group.name === "general-data_1") {
                    group.questions.forEach((question) => {
                        if (question.name === "general-data_1_4") {
                            question.validation.options_list.forEach((optionlist) => {
                                optionlist.options.forEach((option) => {
                                    if (option.key === answerValue.toString()) {
                                        valueFromOptions = option.value;
                                    };
                                });
                            });
                        };
                    });
                };
            });
        });
        return valueFromOptions;
    };

    return (
        <>
            <div className='title'>
                <Typography variant="h4" gutterBottom >
                    INFORMAÇÕES DA EMPRESA:
                </Typography>
            </div>
            <Grid container spacing={0} className="grid-container">
                <Grid item xs={4} className="item-response">
                    <h4>Nome da empresa: </h4>
                </Grid>
                <Grid item xs={8} className="item-response">
                    <h4>{currProcesso.razaoSocial}</h4>
                </Grid>
                <Grid item xs={4} className="item-response">
                    <h4>CNPJ: </h4>
                </Grid>
                <Grid item xs={8} className="item-response">
                    <h4>{currProcesso.cnpj}</h4>
                </Grid>
                <Grid item xs={4} className="item-response">
                    <h4>Segmento Industrial: </h4>
                </Grid>
                <Grid item xs={8} className="item-response">
                    <h4>{cmpcapaSegmento}</h4>
                </Grid>
                <Grid item xs={4} className="item-response">
                    <h4>Subsegmento Industrial: </h4>
                </Grid>
                <Grid item xs={8} className="item-response">
                    <h4>{getValueFromOptionsList('gerneral_data_1_4', parseInt(lstRespostas.prediagnostico['general-data_1_4']))}</h4>
                </Grid>
                <Grid item xs={4} className="item-response">
                    <h4>Estado: </h4>
                </Grid>
                <Grid item xs={8} className="item-response">
                    <h4>{currProcesso.estado}</h4>
                </Grid>
                <Grid item xs={4} className="item-response">
                    <h4>Endereço: </h4>
                </Grid>
                <Grid item xs={8} className="item-response">
                    <h4>{currProcesso.rua}, {currProcesso.numero} - {currProcesso.bairro} / {currProcesso.cidade} ({currProcesso.estado})</h4>
                </Grid>
                <Grid item xs={4} className="item-response">
                    <h4>Área total (m²): </h4>
                </Grid>
                <Grid item xs={8} className="item-response">
                    <h4>{formatInteger(parseInt(lstRespostas.prediagnostico['general-data_1_7']))}</h4>
                </Grid>
                <Grid item xs={4} className="item-response">
                    <h4>Hora de funcionamento início: </h4>
                </Grid>
                <Grid item xs={8} className="item-response">
                    <h4>{lstRespostas.prediagnostico['general-data_1_9']}</h4>
                </Grid>
                <Grid item xs={4} className="item-response">
                    <h4>Hora de funcionamento fim: </h4>
                </Grid>
                <Grid item xs={8} className="item-response">
                    <h4>{lstRespostas.prediagnostico['general-data_1_10']}</h4>
                </Grid>
                <Grid item xs={4} className="item-response">
                    <h4>Dias Operação por ano: </h4>
                </Grid>
                <Grid item xs={8} className="item-response">
                    <h4>{formatInteger(parseInt(lstRespostas.prediagnostico['general-data_1_11']))}</h4>
                </Grid>
            </Grid>
        </>
    );
};

export default RelatorioResultadoDadosGerais;