export const loadNames = [
  "lighting_2",
  "hvac_2",
  "motors-drivers_2",
  "pumping_2",
  "compressed-air_2",
  "ventilation-exhaust_2",
  "oven-hearters_2",
  "steam-boilers_2",
  "refrigeration_2",
  "other-actions-considered_2",
];

export const fuelQuestions = [
  "general-data_3_1",
  "general-data_3_2",
  "general-data_3_3",
  "general-data_3_4",
  "general-data_3_5",
  "general-data_3_7",
  "general-data_3_8",
  "general-data_3_9",
  "general-data_3_10",
  "general-data_3_11",
  "general-data_3_12",
  "general-data_3_13",
  "general-data_3_14",
  "general-data_3_15",
  "general-data_3_16",
  "general-data_3_17",
];

export const loadQuestions = [
  "existing-loads_1",
  "existing-loads_2",
  "existing-loads_3",
  "existing-loads_4",
  "existing-loads_5",
  "existing-loads_6",
  "existing-loads_7",
  "existing-loads_8",
  "existing-loads_9",
  "existing-loads_10",
];

export const configVisibility = {
  "general-data_7_": {
    stepName: "general-data_7",
    decisionKey: "general-data_7_1",
    baseKey: "general-data_7_",
    threshold: 4,
  },
  "general-data_8_": {
    stepName: "general-data_8",
    decisionKey: "general-data_8_1",
    baseKey: "general-data_8_",
    threshold: 4,
  },
  "general-data_8_5": {
    stepName: "general-data_8",
    decisionKey: "general-data_8_5",
    baseKey: "general-data_8_5",
    threshold: 1,
  },
  "general-data_9_": {
    stepName: "general-data_9",
    decisionKey: "general-data_9_1",
    baseKey: "general-data_9_",
    threshold: 3,
  },
  "general-data_10_": {
    stepName: "general-data_10",
    decisionKey: "general-data_10_1",
    baseKey: "general-data_10_",
    threshold: 3,
  },
};

export const steamBoilersQuestionNames = [
  "steam-boilers_2_2",
  "steam-boilers_2_3",
  "steam-boilers_2_4",
  "steam-boilers_2_5",
  "steam-boilers_2_6",
  "steam-boilers_2_7",
  "steam-boilers_2_8",
  "steam-boilers_2_9",
  "steam-boilers_2_10",
  "steam-boilers_2_11",
  "steam-boilers_2_12",
  "steam-boilers_2_13",
  "steam-boilers_2_14",
  "steam-boilers_2_15",
  "steam-boilers_2_16",
  "steam-boilers_2_17",
  "steam-boilers_2_18",
  "steam-boilers_2_19",
  "steam-boilers_2_20",
  "steam-boilers_2_21",
  "steam-boilers_2_22",
  "steam-boilers_2_23",
  "steam-boilers_2_24",
  "steam-boilers_2_25",
  "steam-boilers_2_26",
  "steam-boilers_2_27",
  "steam-boilers_2_28",
  "steam-boilers_2_29",
  "steam-boilers_2_30",
  "steam-boilers_2_31",
  "steam-boilers_2_32",
  "steam-boilers_2_33",
  "steam-boilers_2_34",
  "steam-boilers_2_35",
  "general-data_2_10",
  "general-data_2_12",
  "general-data_2_5",
  "general-data_2_10",
  "general-data_2_12",
  "general-data_2_14",
  "oven-hearters_2_3",
  "oven-hearters_2_7",
  "oven-hearters_2_9",
  "oven-hearters_2_10",
  "oven-hearters_2_20",
  "oven-hearters_2_24",
  "general-data_8_6",
  "general-data_9_5",
];

export const electricityTariffModality = [
  "general-data_2_5",
];

export const electricity29 = [
  "general-data_2_10",
];

export const electricity211 = [
  "general-data_2_12",
];

export const solarCollectorsYes = [
  "general-data_9_2",
  "general-data_9_3",
];

export const solarCollectorsNo = [
  "general-data_9_4",
];

export const solarCollectors94 = [
  "general-data_9_5",
  "general-data_9_6",
];

export const fotovoltaico85 = [
  "general-data_8_6",
];

export const highVoltage = [
  "general-data_2_14",
]

export const ovenheartersEletric = [
  "oven-hearters_2_3",
  "oven-hearters_2_7",
];

export const ovenheartersTermicIsolation = [
  "oven-hearters_2_9",
  "oven-hearters_2_10",
];

export const ovenheartersFuelOil = [
  "oven-hearters_2_3",
  "oven-hearters_2_20",
];

export const ovenheartersGLP = [
  "oven-hearters_2_3",
];

// export const refrigeration = [
//   "refrigeration_2_28",
// ];

export const refrigeration225 = [
  "refrigeration_2_26",
  "refrigeration_2_27",
  "refrigeration_2_28",
  "refrigeration_2_29",
];

export const refrigeration226 = [
  "refrigeration_2_27",
  "refrigeration_2_28",
  "refrigeration_2_29",
];

export const refrigeration227 = [
  "refrigeration_2_28",
  "refrigeration_2_29",
];

export const refrigeration228 = [
  "refrigeration_2_29",
];

export const steamboilers214 = [
  "steam-boilers_2_14",
];

export const steamboilers215 = [
  "steam-boilers_2_15",
];


export const steamboilers29 = [
  "steam-boilers_2_10",
];

export const steamboilers32 = [
  "steam-boilers_2_32",
];


export const exceptionStep = ["general-data", "consolidated-results"];

export const reportNames = ["capex", "nocapex", "outrasAcoes"];
