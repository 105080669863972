import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
} from "@mui/material";
import './ModalRelResumo.css';

const RelatorioResultadoOutrasAcoes = ({ lstQuestionario, lstRespostas }) => {
  const [exibirDados, setExibirDados] = React.useState(null);
  const [listOutrasAcoes, setListOutrasAcoes] = React.useState([]);

  // const getQuestionsAndAnswers = (groupName) => {
  //   const questionsAndAnswers = [];

  //   lstQuestionario.steps.forEach((step) => {
  //     step.groups?.forEach((group) => {
  //       if (group.name === groupName) {
  //         group.questions?.forEach((question) => {
  //           //const  resultadoResposta = (parseInt(lstRespostas.prediagnostico[question.referenceQuestion]) === 1 ? true : false);
  //           const resultadoResposta = parseInt(lstRespostas.prediagnostico[question.referenceQuestion]);

  //           if (question.energyEficiencySugestion === resultadoResposta) {
  //             questionsAndAnswers.push({
  //               questionName: question.name,
  //               questionDescription: question.description,
  //               questionObservation: question.observations
  //             });
  //           }
  //         });
  //       }
  //     });
  //   });

  //   return questionsAndAnswers;
  // };
  const getQuestionsAndAnswers = (groupName) => {
    const questionsAndAnswers = [];
    let incluirpergunta = false;
    let index = 0;
    lstQuestionario.steps.forEach((step) => {
      step.groups?.forEach((group) => {
        if (group.name === groupName) {
          group.questions?.forEach((question) => {
            //const  resultadoResposta = (parseInt(jsonBanco.prediagnostico[question.referenceQuestion]) === 1 ? true : false);
            const resultadoResposta = parseInt(lstRespostas.prediagnostico[question.referenceQuestion]);
            incluirpergunta = false;
            console.log("========================");
            console.log(question.name);
            console.log("========================");
            if (question.energyEficiencySugestion === resultadoResposta) {
              if (question.name === "other-actions-considered_2_4") {
                console.log("========================");
                console.log("outras ações ");
                console.log("resposta ", lstRespostas.prediagnostico["general-data_8_1"]);
                console.log(lstRespostas.prediagnostico["general-data_8_5"]);
                console.log(lstRespostas.prediagnostico["general-data_8_6"]);
                if (
                  lstRespostas.prediagnostico["general-data_8_1"] === "0" &&
                  lstRespostas.prediagnostico["general-data_8_5"] === "1"
                  /*
                     regra indicada pelo vitor ,não para possui sistema fotovoltaico, e sim para a area disponivel
                     &&
                  (jsonBanco.prediagnostico["general-data_8_6"] !== null && jsonBanco.prediagnostico["general-data_8_6"] !== undefined)
                  */
                ) {
                  incluirpergunta = true;
                }
              }
              else {
                if (question.name === "other-actions-considered_2_10") {
                  if (lstRespostas.prediagnostico["general-data_7_1"] === "1"
                    && parseInt(lstRespostas.prediagnostico["general-data_7_3"], 10) > 2080
                    && parseInt(lstRespostas.prediagnostico["general-data_7_4"], 10) > 100
                  ) {
                    incluirpergunta = true;
                  }
                }
                if (question.name === "other-actions-considered_2_11") {
                  console.log("========================");
                  console.log("outras ações_2_11 ");
                  console.log("resposta ", lstRespostas.prediagnostico["general-data_9_1"]);
                  console.log("resposta ", lstRespostas.prediagnostico["general-data_9_4"]);
                  console.log("resposta ", lstRespostas.prediagnostico["general-data_9_6"]);
                  if (lstRespostas.prediagnostico["general-data_9_1"] === "0"
                    && lstRespostas.prediagnostico["general-data_9_4"] === "1"
                    && lstRespostas.prediagnostico["general-data_9_6"] === "1"
                  ) {
                    incluirpergunta = true;
                  }
                }
                else {
                  incluirpergunta = true;
                }
              }


              if (incluirpergunta) {
                index += 1;
                questionsAndAnswers.push({
                  questionName: question.name,
                  questionDescription: question.description,
                  questionObservation: question.observations,
                  idx: index
                });
              }
            }
          });
        }
      });
    });

    return questionsAndAnswers;
  };

  useEffect(() => {
    setExibirDados(1);
    setListOutrasAcoes(getQuestionsAndAnswers("other-actions-considered_2"));
  }, []);

  return (
    <div style={{
      pageBreakInside: 'avoid'
    }}>
      <Box className='title'>
        <Typography variant="h4" gutterBottom>
          Outras ações consideradas(idx)
        </Typography>
      </Box>
      <Grid container spacing={0} className='grid-container'>
        <Grid item xs={12}>
          <ol>
            {listOutrasAcoes.map((item, index) => (
              <li key={index}>
                <Typography variant="subtitle1" gutterBottom>
                  {item.idx}. {item.questionObservation}
                </Typography>
              </li>
            ))}
          </ol>
        </Grid>
        {/* {listOutrasAcoes.map((item, index) => (
          <>
            <Grid item xs={12} className='item-response-outrasacoes'>

              <Typography variant="subtitle1" gutterBottom>
                {index + 1}. {item.questionObservation}
              </Typography>
            </Grid>
          </>
        ))} */}
      </Grid>
    </div>
  );
};

export default RelatorioResultadoOutrasAcoes;