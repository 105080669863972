import MaterialTable, { MTableToolbar } from "@material-table/core";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Link,
} from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import * as React from "react";
import { DialogoVisualizador } from "../../components";
import { driveBase, materialTableLocalization } from "../../config";
import api from "../../services/api";

const DialogoOrcamentosFornecedor = ({ open, onClose, fornecedor }) => {
  let loading = useStoreState((state) => state.nav.loading);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [columns, setColumns] = React.useState([]);
  const [orcamentoRespondido, setOrcamentoRespondido] = React.useState(false);
  const tableRef = React.useRef();

  function calcularPrazo(dataEnvio) {
    const prazoEmDias = 15;
    const hoje = new Date();
    const diffEmDias = Math.floor((hoje - dataEnvio) / (1000 * 60 * 60 * 24));

    if (diffEmDias > prazoEmDias) {
      return 0; // Prazo expirado
    } else {
      return prazoEmDias - diffEmDias;
    }
  }

  const loadOrcamentos = async (query) => {
    if (fornecedor === null) {
      return;
    }

    startLoading();

    try {
      let estagios = (await api.http.get("/estagios")).data;
      const response = await api.http.get(
        "/fornecedores/" + fornecedor.id + "/orcamentos",
        {
          params: {
            fornecedorId: fornecedor.id,
            somenteOrcamentosRespondidos: orcamentoRespondido,
            page: query.page + 1,
            pageSize: query.pageSize,
            search: query.search,
            orderBy: query.orderBy ? query.orderBy.field : undefined,
            orderDirection: query.orderDirection
              ? query.orderDirection
              : undefined,
          },
        }
      );
      const mappedResponse = response.data.data.map((orcamento) => {
        const dataEnvio = new Date(orcamento.created_at);
        return {
          ...orcamento,
          created_at: new Date(orcamento.created_at).toLocaleDateString(),
          prazo: calcularPrazo(dataEnvio),
          estagio: estagios.filter(
            (estagio) => estagio.id === orcamento.estagio
          )[0].nome,
        };
      });
      stopLoading();
      return {
        data: mappedResponse,
        page: response.data.meta.current_page - 1,
        totalCount: response.data.meta.total,
      };
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  React.useEffect(() => {
    tableRef.current?.onQueryChange();
  }, [orcamentoRespondido]);

  const geturlsignedaws = async (id) => {
    const dados = (await api.http.get(`/fornecedores/orcamentos/${id}/getsignedaws`)).data;
    return dados.fileRef;
  };


  const openDoc = async (arquivo) => {
    const arquivoaws = await geturlsignedaws(arquivo.fileRef);

    if (
      arquivo.mimeType == "application/pdf" ||
      arquivo.mimeType == "text/html" ||
      arquivo.mimeType?.indexOf("image") == 0
    ) {

      arquivo.fileRef = arquivoaws;

      setFile(arquivo);
      setOpenDlgVis(true);
    } else {

      // //  window.location.href = driveBase + arquivo.fileRef;
      window.location.href = driveBase + arquivoaws;
    }
  };

  React.useEffect(() => {
    setColumns([
      {
        field: "processoId",
        align: "left",
        title: "Cód. Processo",
      },
      {
        field: "razao_social",
        align: "left",
        title: "Razão Social",
      },
      {
        field: "label",
        align: "left",
        title: "Tecnologia",
      },
      {
        field: "docOrcamento",
        align: "left",
        title: "Status",
        render: (rowData) => {
          if (rowData.file_name)
            return (
              <Link
                onClick={() => {
                  openDoc({
                    fileName: rowData.file_name,
                    mimeType: rowData.mime_type,
                    fileRef: rowData.file_ref,
                  });
                }}
              >
                {rowData.file_name}
              </Link>
            );
          else return "Aguardando orçamento";
        },
      },
      {
        field: "estagio",
        align: "left",
        title: "Etapa",
      },
      {
        field: "created_at",
        align: "left",
        title: "Data de Envio",
      },
      {
        field: "prazo",
        align: "center",
        title: "Prazo (dias)",
      },
    ]);
  }, [loading]);

  const [file, setFile] = React.useState(null);
  const [openDlgVis, setOpenDlgVis] = React.useState(false);

  const onCloseDlgVis = () => {
    setFile(null);
    setOpenDlgVis(false);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth={true}>
      <DialogContent>
        <DialogoVisualizador
          file={file}
          open={openDlgVis}
          onClose={onCloseDlgVis}
        />
        <MaterialTable
          tableRef={tableRef}
          columns={columns}
          data={loadOrcamentos}
          title="Orçamentos"
          localization={materialTableLocalization}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div
                  style={{
                    padding: "0px 65px 0px 0px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={orcamentoRespondido}
                        onChange={() => {
                          setOrcamentoRespondido(!orcamentoRespondido);
                        }}
                      />
                    }
                    label={"Orçamento Respondido"}
                  />
                </div>
              </div>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        {loading > 0 && (
          <CircularProgress
            size={30}
            sx={{
              position: "absolute",
              left: 25,
              bottom: 12,
            }}
          />
        )}
        <Button
          color="primary"
          onClick={() => {
            onClose();
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogoOrcamentosFornecedor;
