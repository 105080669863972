export function formatCurrency(value){
    return new Intl.NumberFormat('pt-BR',{style: 'currency', currency: 'BRL'},{}).format(value);
}

export function formatInteger(value) {
    return new Intl.NumberFormat('pt-BR').format(value);
}

export function formatDate(date) {
    return new Intl.DateTimeFormat('pt-BR').format(date);
}

export function formatDecimal(value, decimalPlaces = 1) {
    return new Intl.NumberFormat('pt-BR', {
      minimumFractionDigits: decimalPlaces, // Número mínimo de casas decimais
      maximumFractionDigits: decimalPlaces, // Número máximo de casas decimais
    }).format(value);
  }

export function formatTime(time) {
    return new Intl.DateTimeFormat('pt-BR', { 
        hour: 'numeric', 
        minute: 'numeric', 
        second: 'numeric',
        hour12: false // Se quiser formato 24 horas, definir como false
    }).format(time);
}