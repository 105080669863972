import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const BarChartReportLate = ({ data }) => {
    // Ordena os dados do maior para o menor valor
    const sortedData = [...data].sort((a, b) => b.total - a.total);

    const labels = sortedData.map(item => item.Estagio);
    const totals = sortedData.map(item => item.total);
    const totalSum = totals.reduce((sum, value) => sum + value, 0);

    const chartData = {
        labels: labels,
        datasets: [
            {
                data: totals,
                backgroundColor: '#61bb46',
                hoverBackgroundColor: '#61bb46',
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false, // Oculta a legenda
                labels: {
                    color: '#FFFFFF' // Cor branca para os textos da legenda (se estivesse habilitada)
                }
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        const label = context.label || '';
                        const value = context.raw;
                        const percentage = ((value / totalSum) * 100).toFixed(2) + '%';
                        return `${label}: ${percentage}`;
                    }
                }
            },
            datalabels: {
                formatter: (value, context) => {
                    const percentage = ((value / totalSum) * 100).toFixed(2) + '%';
                    return percentage;
                },
            }
        },
        indexAxis: 'x', // Define o eixo X para os rótulos e o Y para os valores
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true,
            }
        }
    };

    return <Bar data={chartData} options={options} />;
};

export default BarChartReportLate;
