import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Skeleton,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import NumberFormat from "react-number-format";
import { Element, scroller } from "react-scroll";
import DataJSON from "../../dataMocks.json";
import api from "../../services/api";
import StepPreDiagnosticoModalRelatorioResumo from "../StepPreDiagnosticoModalRelatorioResumo";
import StepPreDiagnosticoRelCapex from "../StepPreDiagnosticoRelCapex";
import StepPreDiagnosticoRelNaoCapex from "../StepPreDiagnosticoRelNaoCapex";
import StepPreDiagnosticoRelOutrasAcoes from "../StepPreDiagnosticoRelOutrasAcoes";
import FileListFieldPreDiagnostico from "./fileListFieldPreDiagnostico";

import {
  configVisibility,
  exceptionStep,
  fuelQuestions,
  loadNames,
  loadQuestions,
  reportNames,
  steamBoilersQuestionNames,
  ovenheartersEletric,
  ovenheartersTermicIsolation,
  ovenheartersFuelOil,
  ovenheartersGLP,
  electricityTariffModality,
  highVoltage,
  solarCollectorsYes,
  solarCollectorsNo,
  refrigeration225,
  refrigeration226,
  refrigeration227,
  refrigeration228,
  electricity211,
  electricity29,
  steamboilers214,
  steamboilers215,
  steamboilers29,
  steamboilers32,
  fotovoltaico85,
  solarCollectors94,
} from "./constants";

const ContainerInput = ({ children }) => (
  <Box sx={{ width: "100%", padding: 2 }}>{children}</Box>
);

const stepsReportsNames = ["capex", "nocapex", "outrasAcoes"];

export default function StepByStepPreDiagnostico2() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const [prediagnostico, setPrediagnostico] = useState({});
  const [loading, setLoading] = useState(true);
  const [steps, setSteps] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentViewStep, setCurrentViewStep] = useState(1);
  const [formValues, setFormValues] = useState({});
  const [stepVisibility, setStepVisibility] = useState({});
  const [questionVisibility, setQuestionVisibility] = useState(
    steamBoilersQuestionNames
  );
  const [subsegmentOptions, setSubsegmentOptions] = useState([]);
  const [stepCompletion, setStepCompletion] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [lastUpdatedField, setLastUpdatedField] = useState("");
  const [resetSteamBoilersQuestion, setResetSteamBoilersQuestion] =
    useState(false);

  const [completedSteps, setCompletedSteps] = useState([]);
  const [incompleteSteps, setIncompleteSteps] = useState([]);
  const [invisibleQuestions, setInvisibleQuestions] = useState([]);
  const [stepBlockedDialog, setStepBlockedDialog] = useState(false);
  const [isAllStepsCompleted, setIsAllStepsCompleted] = useState(false);
  const [activeReports, setActiveReports] = useState(false);

  const [visibleItensSteps, setVisibleItensSteps] = useState(false);

  //Joao Carlos - 15/05/2024
  const [modalSystem, setModalSystem] = useState("");
  const [modalSystemObs, setModalSystemObs] = useState("");

  let currProcesso = useStoreState((state) => state.processo.current);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

  const {
    handleSubmit,
    control,
    setValue,
    setError,
    clearErrors,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  const generateSteps = () => {
    setLoading(true);
    let generatedSteps = DataJSON.steps.flatMap((step) => {
      if (step.groups.length > 0) {
        return [
          { ...step, isSubstep: false },
          ...step.groups.map((group) => ({
            ...group,
            isSubstep: true,
            visibility: true,
            type: "group",
          })),
        ];
      }
      return { ...step, isSubstep: false, type: "step" };
    });

    const initialStepVisibility = generatedSteps.reduce((acc, step) => {
      if (step.name.startsWith("fuel-data-") || loadNames.includes(step.name)) {
        acc[step.name] = stepVisibility[step.name];
      } else {
        acc[step.name] = true;
      }
      return acc;
    }, {});

    setSteps(generatedSteps);
    setStepVisibility(initialStepVisibility);
    handleGetPreDiagnosticos(initialStepVisibility, generatedSteps);
  };

  const openModal = (e) => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateQuestionVisibility = (fullData, steps) => {
    const newInvisibleQuestions = { ...invisibleQuestions };

    const onlySteps = [
      "general-data_2",
      "general-data_7",
      "general-data_8",
      "general-data_9",
      "general-data_10",
    ];

    const listQuestionOffJoice = [
      "general-data_2_5",
      "general-data_2_10",
      "general-data_2_12",
      "general-data_2_14",
    ];

    steps.forEach((step) => {
      if (!onlySteps.includes(step.name)) return;

      step.questions.forEach((question) => {
        const shouldHide = !shouldShowField(
          question.name,
          watch,
          setValue,
          configVisibility
        );

        if (shouldHide) { //&& shouldHideQuestionsPerson) {
          newInvisibleQuestions[question.name] = true;
        } else {
          delete newInvisibleQuestions[question.name];
        }
      });
    });
    setInvisibleQuestions(newInvisibleQuestions);
    return newInvisibleQuestions;
  };

  const handleGetPreDiagnosticos = async (visibilitySteps = [], steps) => {
    try {
      const response = await api.http.get(
        `/prediagnosticos/${currProcesso.id}`
      );
      let data = response.data;

      data.prediagnostico = JSON.parse(data.prediagnostico);
      data.processoId = data.processoId;
      data.id = data.id;
      data.currentStep = data.currentStep;
      data.isCompleted = data.isCompleted;

      const dataProcesso = {
        "general-data_1_3":
          data.prediagnostico["general-data_1_3"] ??
          currProcesso.setorAtuacaoId.toString(),
      };

      const fullData = { ...dataProcesso, ...data.prediagnostico };

      reset(fullData);

      setPrediagnostico(data);
      setCurrentStep(data.currentStep);
      setIsAllStepsCompleted(data.isCompleted);

      const steamBoilersQuestions = Object.keys(fullData).filter((question) =>
        question.startsWith("steam-boilers_2")
      );

      const newSteamBoilersQuestionNames = steamBoilersQuestionNames.filter(
        (question) => !steamBoilersQuestions.includes(question)
      );

      const generalData2Questions = Object.keys(fullData).filter((question) =>
        question.startsWith("general-data_2")
      );

      const newGeneralData2QuestionNames = steamBoilersQuestionNames.filter(
        (question) => generalData2Questions.includes(question)
      );

      const newVisibility = newSteamBoilersQuestionNames.filter(
        (question) => !newGeneralData2QuestionNames.includes(question)
      );

      setQuestionVisibility(newVisibility);

      const currentInvisibleQuestions = updateQuestionVisibility(
        fullData,
        steps
      );

      const makeStepItensInvisible = await makeStepItensVisible();

      steps.forEach((step, index) => {
        if (!step.isSubstep && step.name !== "existing-loads") return;

        const stepQuestions = step.questions.map((q) => q.name);
        const hasAnswer = stepQuestions.some((q) => fullData.hasOwnProperty(q));
        if (hasAnswer) {
          handleIsIsCompleted(index, steps, currentInvisibleQuestions);
        }
      });

      setLoading(false);
    } catch (error) {
      console.error("Falha ao buscar prediagnostico:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    generateSteps();
  }, []);

  useEffect(() => {
    if (watch("general-data_1_3") !== undefined) {
      updateSegment();
    }
  }, [watch("general-data_1_3")]);

  ////inicio métodos ocultar campos por seleção

  useEffect(() => {
    if (
      steps[currentStep] &&
      steps[currentStep].name === "general-data_8"
    ) {
      if (watch("general-data_8_1") === "1") {
        setValue("general-data_8_5", "");
        setValue("general-data_8_6", "");
      } else {
        setValue("general-data_8_2", "");
        setValue("general-data_8_3", "");
        setValue("general-data_8_4", "");
      };
    };
  }, [watch("general-data_8_1")]);

  useEffect(() => {
    if (
      steps[currentStep] &&
      steps[currentStep].name === "general-data_8"
    ) {
      if (watch("general-data_8_5") === "0") {
        setQuestionVisibility([...questionVisibility, ...fotovoltaico85]);
        setValue("general-data_8_6", "");
      }
      if (watch("general-data_8_5") === "1") {
        setQuestionVisibility(questionVisibility.filter(item => !fotovoltaico85.includes(item)));
      }
    }
  }, [watch("general-data_8_5")]);


  useEffect(() => {
    if (
      steps[currentStep] &&
      steps[currentStep].name === "general-data_9"
    ) {
      if (watch("general-data_9_1") === "1") {
        setValue("general-data_9_4", "");
        setValue("general-data_9_5", "");
        setValue("general-data_9_6", "");
      } else {
        setValue("general-data_9_2", "");
        setValue("general-data_9_3", "");
      };
    };
  }, [watch("general-data_9_1")]);



  useEffect(() => {
    if (
      steps[currentStep] &&
      steps[currentStep].name === "general-data_9"
    ) {
      if (watch("general-data_9_4") === "0") {
        setQuestionVisibility([...questionVisibility, ...solarCollectors94]);
        setValue("general-data_9_5", "");
      }
      if (watch("general-data_9_4") === "1") {
        setQuestionVisibility(questionVisibility.filter(item => !solarCollectors94.includes(item)));
      }
    }
  }, [watch("general-data_9_4")]);

  useEffect(() => {
    if (
      steps[currentStep] &&
      steps[currentStep].name === "general-data_10"
    ) {
      if (watch("general-data_10_1") === "0") {
        setValue("general-data_10_2", "");
        setValue("general-data_10_3", "");
      };
    };
  }, [watch("general-data_10_1")]);

  useEffect(() => {
    if (
      steps[currentStep] &&
      steps[currentStep].name === "general-data_7"
    ) {
      if (watch("general-data_7_1") === "0") {
        setValue("general-data_7_2", "");
        setValue("general-data_7_3", "");
        setValue("general-data_7_4", "");
      };
    };
  }, [watch("general-data_7_1")]);

  useEffect(() => {
    if (
      steps[currentStep] &&
      steps[currentStep].name === "general-data_9"
    ) {
      if (watch("general-data_9_1") !== "0") {
        setQuestionVisibility([...questionVisibility, ...solarCollectorsNo]);
        setQuestionVisibility(questionVisibility.filter(item => !solarCollectorsYes.includes(item)));
      }
      else {
        setQuestionVisibility([...questionVisibility, ...solarCollectorsYes]);
        setQuestionVisibility(questionVisibility.filter(item => !solarCollectorsNo.includes(item)));
      }
    }
  }, [watch("general-data_9_1")]);

  ////=============  general-data_2
  useEffect(() => {
    if (visibleItensSteps &&
      steps[currentStep] &&
      steps[currentStep].name === "general-data_2"
    ) {
      if (watch("general-data_2_13") !== "1") {
        setQuestionVisibility([...questionVisibility, ...highVoltage]);
        setValue("general-data_2_14", "");
      }
      else {
        setQuestionVisibility(questionVisibility.filter(item => !highVoltage.includes(item)));
      }
    }
  }, [watch("general-data_2_13")]);

  useEffect(() => {
    if (visibleItensSteps &&
      steps[currentStep] &&
      steps[currentStep].name === "general-data_2"
    ) {
      if (watch("general-data_2_9") !== "1") {
        setQuestionVisibility([...questionVisibility, ...electricity29]);
        setValue("general-data_2_10", "");
      }
      else {
        setQuestionVisibility(questionVisibility.filter(item => !electricity29.includes(item)));
      }
    }
  }, [watch("general-data_2_9")]);



  useEffect(() => {
    if (visibleItensSteps &&
      steps[currentStep] &&
      steps[currentStep].name === "general-data_2"
    ) {
      if (watch("general-data_2_11") !== "1") {
        setQuestionVisibility([...questionVisibility, ...electricity211]);
        setValue("general-data_2_12", "");
      }
      else {
        setQuestionVisibility(questionVisibility.filter(item => !electricity211.includes(item)));
      }
    }
  }, [watch("general-data_2_11")]);

  useEffect(() => {
    if (visibleItensSteps &&
      steps[currentStep] &&
      steps[currentStep].name === "general-data_2"
    ) {
      if (watch("general-data_2_3") === "3") {
        setQuestionVisibility([...questionVisibility, ...electricityTariffModality]);
        setValue("general-data_2_5", "");
      }
      else {
        setQuestionVisibility(questionVisibility.filter(item => !electricityTariffModality.includes(item)));
      }
    }
  }, [watch("general-data_2_3")]);

  ////=============  refrigeration_2
  useEffect(() => {
    if (visibleItensSteps &&
      steps[currentStep] &&
      steps[currentStep].name === "refrigeration_2"
    ) {
      if (watch("refrigeration_2_25") === "0") {
        setQuestionVisibility([...questionVisibility, ...refrigeration225]);
        setValue("refrigeration_2_26", "");
        setValue("refrigeration_2_27", "");
        setValue("refrigeration_2_28", "");
        setValue("refrigeration_2_29", "");
      }
      else {
        setQuestionVisibility(questionVisibility.filter(item => !refrigeration225.includes(item)));
      }
    }
  }, [watch("refrigeration_2_25")]);

  useEffect(() => {
    if (visibleItensSteps &&
      steps[currentStep] &&
      steps[currentStep].name === "refrigeration_2"
    ) {
      if (watch("refrigeration_2_26") === "1") {
        setQuestionVisibility([...questionVisibility, ...refrigeration226]);
        setValue("refrigeration_2_27", "");
        setValue("refrigeration_2_28", "");
        setValue("refrigeration_2_29", "");
      }
      else {
        setQuestionVisibility(questionVisibility.filter(item => !refrigeration226.includes(item)));
      }
    }
  }, [watch("refrigeration_2_26")]);

  useEffect(() => {
    if (visibleItensSteps &&
      steps[currentStep] &&
      steps[currentStep].name === "refrigeration_2"
    ) {
      if (watch("refrigeration_2_27") === "0") {
        setQuestionVisibility([...questionVisibility, ...refrigeration227]);
        setValue("refrigeration_2_28", "");
        setValue("refrigeration_2_29", "");
      }
      else {
        setQuestionVisibility(questionVisibility.filter(item => !refrigeration227.includes(item)));
      }
    }
  }, [watch("refrigeration_2_27")]);

  useEffect(() => {
    if (visibleItensSteps &&
      steps[currentStep] &&
      steps[currentStep].name === "refrigeration_2"
    ) {
      if (watch("refrigeration_2_28") === "0") {
        setQuestionVisibility([...questionVisibility, ...refrigeration228]);
        setValue("refrigeration_2_29", "");
      }
      else {
        setQuestionVisibility(questionVisibility.filter(item => !refrigeration228.includes(item)));
      }
    }
  }, [watch("refrigeration_2_28")]);

  ////=============  oven-hearters_2
  useEffect(() => {
    if (visibleItensSteps &&
      steps[currentStep] &&
      steps[currentStep].name === "oven-hearters_2"
    ) {

      setTimeout(() => {

        // ocultar as perguntas
        if (watch("oven-hearters_2_2") === "1" ||
          watch("oven-hearters_2_2") === "2" ||
          watch("oven-hearters_2_2") === "6" ||
          watch("oven-hearters_2_2") === "11"
        ) {
          setQuestionVisibility([...questionVisibility, ...ovenheartersGLP]);
          setValue("oven-hearters_2_3", "");
        }

        ////================= ELETRICIDADE
        if (watch("oven-hearters_2_2") === "7") {
          setQuestionVisibility([...questionVisibility, ...ovenheartersEletric]);
        };

        ////================= OLEO COMBUSTIVEL E OLEO DIESEL
        if (watch("oven-hearters_2_2") === "12" || watch("oven-hearters_2_2") === "13") {
          const filteredData = questionVisibility.filter(item => !ovenheartersFuelOil.includes(item));
          setQuestionVisibility(filteredData);
        }

        ////================= carvão
        if (
          watch("oven-hearters_2_2") === "5" ||
          watch("oven-hearters_2_2") === "8" ||
          watch("oven-hearters_2_2") === "9" ||
          watch("oven-hearters_2_2") === "10" ||
          watch("oven-hearters_2_2") === "14") {

          const filteredData = questionVisibility.filter(item => !ovenheartersGLP.includes(item));
          setQuestionVisibility(filteredData);
        }

        setTimeout(() => {
          if (
            watch("oven-hearters_2_2") === "6" ||
            watch("oven-hearters_2_2") === "11" ||
            watch("oven-hearters_2_2") === "15" ||
            watch("oven-hearters_2_2") === "16" ||
            watch("oven-hearters_2_2") === "17") {
            const viewoh2_24 = ["oven-hearters_2_24"];
            const filteredData = questionVisibility.filter(item => !viewoh2_24.includes(item));
            setQuestionVisibility(filteredData);
          }
          else {
            const viewoh2_24 = ["oven-hearters_2_24"];
            setValue("oven-hearters_2_24", "");
            setQuestionVisibility([...questionVisibility, ...viewoh2_24]);
          }
        }, 50)

      }, 80);
    }
  }, [watch("oven-hearters_2_2")]);

  useEffect(() => {
    if (visibleItensSteps &&
      steps[currentStep] &&
      steps[currentStep].name === "oven-hearters_2"
    ) {
      if (watch("oven-hearters_2_8") === "0") {
        setQuestionVisibility([...questionVisibility, ...ovenheartersTermicIsolation]);
        setValue("oven-hearters_2_9", "");
        setValue("oven-hearters_2_10", "");
      }
      else {
        setQuestionVisibility(questionVisibility.filter(item => !ovenheartersTermicIsolation.includes(item)));
      }
    }
  }, [watch("oven-hearters_2_8")]);

  useEffect(() => {
    if (visibleItensSteps &&
      steps[currentStep] &&
      steps[currentStep].name === "steam-boilers_2"
    ) {
      if (watch("steam-boilers_2_1") === "0") {
        setTimeout(() => {
          setValue("steam-boilers_2_14", "");
          let filteredData = questionVisibility.filter(item => !steamboilers215.includes(item));
          setQuestionVisibility([...filteredData, ...steamboilers214]);
        }, 50);
      }
    };
  }, [watch("steam-boilers_2_13")]);

  useEffect(() => {
    if (visibleItensSteps &&
      steps[currentStep] &&
      steps[currentStep].name === "steam-boilers_2") {
      console.log("steam-boilers_2_1: ", watch("steam-boilers_2_1"));
      if (watch("steam-boilers_2_1") === "0") {
        console.log("questionVisibility: ", questionVisibility);
        const notview2_34 = ["steam-boilers_2_34"];
        const view2_35 = ["steam-boilers_2_35"]
        let filteredData = questionVisibility.filter(item => !view2_35.includes(item)).filter(item => !notview2_34.includes(item));
        setQuestionVisibility(filteredData);
      }
    }
  }, [watch("steam-boilers_2_33")])

  useEffect(() => {
    if (visibleItensSteps &&
      steps[currentStep] &&
      steps[currentStep].name === "steam-boilers_2"
    ) {
      if (watch("steam-boilers_2_3") === "0") {
        if (watch("steam-boilers_2_9") === "1") {
          setTimeout(() => {

            //1) OCULTAR A IMAGEM - PEGUNTA PARA SUBIR FOTOS
            //2) EXIBIR A PERGUNTA  "steam-boilers_2_10"
            // setValue("steam-boilers_2_14", "");
            let filteredData = questionVisibility.filter(item => !steamboilers29.includes(item));
            setQuestionVisibility([...filteredData, ...steamboilers32]);

          }, 250);
        };
      }
    };
  }, [watch("steam-boilers_2_9")]);

  useEffect(() => {
    if (visibleItensSteps &&
      steps[currentStep] &&
      steps[currentStep].name === "steam-boilers_2"
    ) {
      if (watch("steam-boilers_2_1") === "0") {
        setTimeout(() => {

          setValue("steam-boilers_2_14", "");
          let filteredData = questionVisibility.filter(item => !steamboilers215.includes(item));
          setQuestionVisibility([...filteredData, ...steamboilers214]);

        }, 50);
      }
    };
  }, [watch("steam-boilers_2_11")]);

  ////=== metodos executados no momento do carregamento do step
  const makeStepItensVisibleGeneralData213 = () => {
    if (watch("general-data_2_13") !== "1") {
      setValue("general-data_2_14", "");
      return highVoltage;
    };
    return [];
  };

  const makeStepItensVisibleGeneralData209 = () => {
    if (watch("general-data_2_9") === "0") {
      return [];
    };
    return electricity29;
  };

  const makeStepItensVisibleGeneralData211 = () => {
    if (watch("general-data_2_11") === "0") {
      return [];
    };
    return electricity211;
  };

  const makeStepItensVisibleGeneralData203 = () => {
    if (watch("general-data_2_3") === "3") {
      setValue("general-data_2_5", "");
      return electricityTariffModality;
    };
    return [];

  };

  const makeStepItensVisiblerefrigeration225 = () => {
    if (watch("refrigeration_2_25") === "0") {
      setValue("refrigeration_2_26", "");
      setValue("refrigeration_2_27", "");
      setValue("refrigeration_2_28", "");
      setValue("refrigeration_2_29", "");
      return refrigeration225;
    };
    return [];
  };

  const makeStepItensVisiblerefrigeration226 = () => {
    if (watch("refrigeration_2_26") === "1") {
      setValue("refrigeration_2_27", "");
      setValue("refrigeration_2_28", "");
      setValue("refrigeration_2_29", "");
      return refrigeration226;
    };
    return [];
  };

  const makeStepItensVisiblerefrigeration227 = () => {
    if (watch("refrigeration_2_27") === "0") {
      setValue("refrigeration_2_28", "");
      setValue("refrigeration_2_29", "");
      return refrigeration227;
    };
    return [];
  };

  const makeStepItensVisiblerefrigeration228 = () => {
    if (watch("refrigeration_2_28") === "0") {
      setValue("refrigeration_2_29", "");
      return refrigeration228;
    };
    return [];
  };

  const makeStepItensVisibleOvenHearters202 = () => {
    ////================= ELETRICIDADE
    if (watch("oven-hearters_2_2") === "7") {
      setValue("oven-hearters_2_3", "");
      setValue("oven-hearters_2_7", "");
      return ovenheartersEletric;
    };

    return [];
  };
  const makeStepItensVisibleOvenHeartersOleos202 = () => {
    ////================= OLEO COMBUSTIVEL E OLEO DIESEL
    if (watch("oven-hearters_2_2") === "12" || watch("oven-hearters_2_2") === "13") {
      //setQuestionVisibility([...questionVisibility, ...ovenheartersGLP]);
      setValue("oven-hearters_2_3", "");
      return ovenheartersGLP;
    };
    return [];
  };

  const makeStepItensVisibleOvenHearters202Exibe = () => {
    ////================= carvão
    if (watch("oven-hearters_2_2") === "5" ||
      watch("oven-hearters_2_2") === "8" ||
      watch("oven-hearters_2_2") === "9" ||
      watch("oven-hearters_2_2") === "10" ||
      watch("oven-hearters_2_2") === "14") {
      return ovenheartersGLP;
    };
    return [];
  };

  const makeStepItensVisibleOvenHearters208Exibe = () => {
    ////================= carvão
    if (watch("oven-hearters_2_2") === "5" ||
      watch("oven-hearters_2_2") === "8" ||
      watch("oven-hearters_2_2") === "9" ||
      watch("oven-hearters_2_2") === "10" ||
      watch("oven-hearters_2_2") === "14") {
      return ovenheartersGLP
    };
    return [];
  };

  const makeStepItensVisibleOvenHearters208 = () => {
    if (watch("oven-hearters_2_8") === "0") {
      setValue("oven-hearters_2_9", "");
      setValue("oven-hearters_2_10", "");
      return ovenheartersTermicIsolation;
    };


    return [];

  };

  const makeStepItensVisible = async () => {



    // if (visibleItensSteps &&
    //   steps[currentStep] &&
    //   steps[currentStep].name === "general-data_9"
    // ) {
    //   ////metodo que exibe os diversos itens do steps
    // }

    if (
      steps[currentStep] &&
      steps[currentStep].name === "general-data_2"
    ) {
      ////metodo que exibe os diversos itens do steps 
      let auxQuestionVisibility = await makeStepItensVisibleGeneralData203();
      const result209 = await makeStepItensVisibleGeneralData209();
      const result211 = await makeStepItensVisibleGeneralData211();
      const result213 = await makeStepItensVisibleGeneralData213();

      const filteredData = questionVisibility.filter(item => !result209.includes(item)).
        filter(item => !result211.includes(item));
      auxQuestionVisibility = [
        ...auxQuestionVisibility,
        ...result213
      ];

      setQuestionVisibility([...filteredData, ...auxQuestionVisibility]);

    }

    if (
      steps[currentStep] &&
      steps[currentStep].name === "refrigeration_2"
    ) {
      ////metodo que exibe os diversos itens do steps 
      let auxQuestionVisibility = await makeStepItensVisiblerefrigeration225();
      const result226 = await makeStepItensVisiblerefrigeration226();
      const result227 = await makeStepItensVisiblerefrigeration227();
      const result228 = await makeStepItensVisiblerefrigeration228();

      auxQuestionVisibility = [
        ...auxQuestionVisibility,
        ...result226,
        ...result227,
        ...result228
      ];

      setQuestionVisibility([...questionVisibility, ...auxQuestionVisibility]);

    }

    if (
      steps[currentStep] &&
      steps[currentStep].name === "oven-hearters_2"
    ) {
      ////metodo que exibe os diversos itens do steps 
      let auxQuestionVisibility = await makeStepItensVisibleOvenHearters208();
      const result202 = await makeStepItensVisibleOvenHearters202();
      const result202a = await makeStepItensVisibleOvenHeartersOleos202();

      auxQuestionVisibility = [
        ...auxQuestionVisibility,
        ...result202,
        ...result202a
      ];

      const result202Exibe = await makeStepItensVisibleOvenHearters202Exibe();
      const result208Exibe = await makeStepItensVisibleOvenHearters208Exibe();

      const filteredData = questionVisibility.filter(item => !result202Exibe.includes(item)).
        filter(item => !result208Exibe.includes(item));

      setQuestionVisibility([...filteredData, ...auxQuestionVisibility]);

    }

    if (
      steps[currentStep] &&
      steps[currentStep].name === "general-data_8"
    ) {
      ////metodo que exibe os diversos itens do steps
      if (watch("general-data_8_5") === "1") {
        setTimeout(() => {
          let filteredData = questionVisibility.filter(item => !fotovoltaico85.includes(item));
          setQuestionVisibility(filteredData);

        }, 60);

      }
    }

    if (
      steps[currentStep] &&
      steps[currentStep].name === "general-data_9"
    ) {
      ////metodo que exibe os diversos itens do steps
      if (watch("general-data_9_4") === "1") {
        setTimeout(() => {
          let filteredData = questionVisibility.filter(item => !solarCollectors94.includes(item));
          setQuestionVisibility(filteredData);

        }, 65);

      }
    }

    // este ponto é depois de exibir ou ocultar os campos personalidados pela Joice

    setVisibleItensSteps(true);
  }

  //// ====== fim métodos ocultar campos por seleção
  useEffect(() => {
    if (
      completedSteps.length ===
      steps.filter(
        (step) =>
          stepVisibility[step.name] &&
          !stepsReportsNames.includes(step.name) &&
          !exceptionStep.includes(step.name)
      ).length
    ) {
      if (!activeReports) {
        setActiveReports(true);
      }
    } else {
      if (activeReports) {
        setActiveReports(false);
      }
    }
  }, [completedSteps, incompleteSteps]);

  function updateSegment() {
    console.log("currentStep", currentStep)

    const segment = steps[currentStep]
      ? steps[currentStep].questions.find((q) => q.name === "general-data_1_3")
      : null;
    if (segment && watch("general-data_1_3")) {
      const selectedOption = segment.validation.options.find(
        (opt) => opt.key === watch("general-data_1_3")
      );

      if (selectedOption) {
        const subsegmentName = selectedOption.name;
        const subsegment = steps[currentStep]
          ? steps[currentStep].questions.find(
            (q) => q.name === "general-data_1_4"
          )
          : null;
        if (subsegment) {
          const selectedSubsegmentOptions =
            subsegment.validation.options_list.find(
              (opt) => opt.name === subsegmentName
            );

          setSubsegmentOptions(
            selectedSubsegmentOptions ? selectedSubsegmentOptions.options : []
          );

          if (
            prediagnostico.prediagnostico &&
            selectedOption.key !==
            prediagnostico.prediagnostico["general-data_1_3"]
          ) {
            setValue("general-data_1_4", "");
          }
        }
      }
    }
  }

  function shouldShowField(fieldName, watch, setValue, configVisibility) {
    let isVisible = true;

    for (const baseKey in configVisibility) {
      const config = configVisibility[baseKey];
      if (fieldName.startsWith(config.baseKey)) {
        if (fieldName === config.decisionKey) return true;

        const decision = watch(config.decisionKey);
        const suffix = parseInt(fieldName.split("_").pop(), 10);

        if (decision === "0") {
          // if ((watch(fieldName) !== "" || watch(fieldName) !== undefined) )
          //   {
          //     setValue(fieldName, undefined);
          //   }
          //if (watch(fieldName)) setValue(fieldName, undefined);
          isVisible = suffix > config.threshold;
          // isVisible = false;
        } else if (
          decision === "true" ||
          decision === true ||
          decision === "1" ||
          decision === "2"
        ) {
          isVisible = suffix > 1 && suffix <= config.threshold;
        } else {
          //isVisible = suffix > config.threshold;
          isVisible = false;
        }

        break;
      }
    }

    if (isVisible && invisibleQuestions[fieldName]) {
      setInvisibleQuestions((prev) => {
        const { [fieldName]: _, ...rest } = prev;
        return rest;
      });
    } else if (!isVisible && !invisibleQuestions[fieldName]) {
      setInvisibleQuestions((prev) => ({
        ...prev,
        [fieldName]: true,
      }));
    }

    return isVisible;
  }

  useEffect(() => {
    if (watch("general-data_1_4") && subsegmentOptions.length > 0) {
      clearErrors("general-data_1_4");
      const proposedLoads = subsegmentOptions.find(
        (opt) => opt.key === watch("general-data_1_4")
      );

      if (proposedLoads) {
        proposedLoads.proposedLoads.forEach((proposed) => {
          const currentValue = watch(proposed.name);

          if (currentValue === undefined) {
            setValue(proposed.name, true);
          }
        });
      }
    }
  }, [watch("general-data_1_4"), subsegmentOptions]);

  //// função que testa se a hora inicial e menor que ahora fial
  // useEffect(() => {
  //   if (steps[currentStep] && steps[currentStep].name !== "general-data_1")
  //     return;

  //   const startTime = watch("general-data_1_9");
  //   const endTime = watch("general-data_1_10");

  //   if (startTime && endTime) {
  //     const time1 = startTime.split(":");
  //     const time2 = endTime.split(":");

  //     const date1 = new Date(0, 0, 0, time1[0], time1[1]);
  //     const date2 = new Date(0, 0, 0, time2[0], time2[1]);

  //     clearErrors("general-data_1_9");
  //     clearErrors("general-data_1_10");

  //     if (date1 > date2) {
  //       setError("general-data_1_10", {
  //         type: "manual",
  //         message: "A hora de término não pode ser menor à hora de início.",
  //       });
  //     } else if (date1 < date2) {
  //     } else if (startTime === endTime) {
  //       setError("general-data_1_9", {
  //         type: "manual",
  //         message: "A hora de início não pode ser igual à hora de término.",
  //       });
  //       setError("general-data_1_10", {
  //         type: "manual",
  //         message: "A hora de término não pode ser igual à hora de início.",
  //       });
  //     }
  //   }
  // }, [
  //   watch("general-data_1_9"),
  //   watch("general-data_1_10"),
  //   setError,
  //   clearErrors,
  //   steps,
  //   currentStep,
  // ]);

  useEffect(() => {
    if (steps.length > 0 && currentStep != 1) {
      updateStepsVisibility();
      updateSegment();
    }
  }, [steps, currentStep]);

  function updateStepsVisibility() {
    const fuelQuestionValues = fuelQuestions
      .map((question) => ({
        name: question,
        value: watch(question),
      }))
      .filter(({ value }) => value === true);

    const loadsQuestionValues = loadQuestions
      .map((question) => ({
        name: question,
        value: watch(question),
      }))
      .filter(({ value }) => value === true);

    const visibleStepsFuel = fuelQuestionValues.map(({ name }) => name);
    const visibleStepsLoads = loadsQuestionValues.map(({ name }) => name);
    const visibleSteps = [...visibleStepsFuel, ...visibleStepsLoads];

    const questions = steps
      .flatMap((step) => {
        if (step.type === "group" || step.isSubstep === false) {
          return step.questions;
        }
        return step;
      })
      .filter((question) => question)
      .filter(
        (question) => question?.proposedQuestion || question?.existingLoadsNames
      );

    const newVisibility = { ...stepVisibility };
    questions.forEach((question) => {
      newVisibility[
        question?.proposedQuestion || question?.existingLoadsNames
      ] = visibleSteps.includes(question.name);
    });

    setStepVisibility(newVisibility);
    handleChangeStep(currentStep, newVisibility, steps);

    return newVisibility;
  }

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name) {
        setLastUpdatedField(name);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const steamBoilersData =
    steps[currentStep] && steps[currentStep].name === "steam-boilers_2"
      ? steps[currentStep].questions.reduce((acc, question) => {
        const value = watch(question.name);
        if (value !== undefined) {
          acc[question.name] = value;
        }
        return acc;
      }, {})
      : null;

  useEffect(() => {
    if (
      steps[currentStep] &&
      steps[currentStep].name === "steam-boilers_2" &&
      resetSteamBoilersQuestion
    ) {
      setResetSteamBoilersQuestion(false);
    }
  }, [lastUpdatedField, steamBoilersData]);

  // useEffect(() => {
  //   if (
  //     steps[currentStep] &&
  //     steps[currentStep].name === "general-data_2" &&
  //     lastUpdatedField !== ""
  //   ) {
  //     const question = steps[currentStep].questions.find(
  //       (q) => q.name === lastUpdatedField
  //     );

  //     if (!question) return;

  //     const value = watch(lastUpdatedField);

  // const visible210 = "general-data_2_10";
  // const visible212 = "general-data_2_12";

  // if (lastUpdatedField === "general-data_2_9") {
  //   if (value === "1") {
  //     setQuestionVisibility((prev) => {
  //       const newVisibility = new Set(prev);
  //       newVisibility.delete(visible210);
  //       return Array.from(newVisibility);
  //     });

  //     setValue("general-data_2_10", "");
  //     setLastUpdatedField("");
  //   } else if (value === "0") {
  //     setQuestionVisibility((prev) => {
  //       const newVisibility = new Set(prev);
  //       newVisibility.add(visible210);
  //       return Array.from(newVisibility);
  //     });
  //     setLastUpdatedField("");
  //   }
  // }

  // if (lastUpdatedField === "general-data_2_11") {
  //   if (value === "1") {
  //     setQuestionVisibility((prev) => {
  //       const newVisibility = new Set(prev);
  //       newVisibility.delete(visible212);
  //       return Array.from(newVisibility);
  //     });
  //     setLastUpdatedField("");
  //     setValue("general-data_2_12", "");
  //   } else if (value === "0") {
  //     setQuestionVisibility((prev) => {
  //       const newVisibility = new Set(prev);
  //       newVisibility.add(visible212);
  //       return Array.from(newVisibility);
  //     });
  //     setLastUpdatedField("");
  //   }
  // }
  // }
  // }, [lastUpdatedField, steamBoilersData]);

  useEffect(() => {
    if (
      steps[currentStep] &&
      steps[currentStep].name === "steam-boilers_2" &&
      !resetSteamBoilersQuestion
    ) {
      const question = steps[currentStep].questions.find(
        (q) => q.name === lastUpdatedField
      );

      if (!question) return;

      const value = steamBoilersData[lastUpdatedField];
      const visibleName = question.visiblename;

      setQuestionVisibility((prev) => {
        const newVisibility = new Set(prev);

        if (visibleName) {
          if (value === "1" || value === "0") {
            newVisibility.delete(visibleName);
          }
        } else {
          if (value === "1") {
            const visibleYes = question.visibleyes;
            const visibleNo = question.visibleno;

            if (newVisibility.has(visibleYes)) {
              newVisibility.delete(visibleYes);
            }

            if (!newVisibility.has(visibleNo)) {
              newVisibility.add(visibleNo);
            }
          } else if (value === "0") {
            const visibleNo = question.visibleno;
            const visibleYes = question.visibleyes;

            if (newVisibility.has(visibleNo)) {
              newVisibility.delete(visibleNo);
            }

            if (!newVisibility.has(visibleYes)) {
              newVisibility.add(visibleYes);
            }
          }
        }

        if (newVisibility.size !== prev.length) {
          return Array.from(newVisibility);
        }

        return prev;
      });
    }
  }, [steamBoilersData, lastUpdatedField]);

  const handleBack = async () => {
    setLoading(true);
    const values = getValues();
    setFormValues((prevValues) => ({ ...prevValues, ...values }));
    let newStep = currentStep - 1;
    while (newStep > 0 && !stepVisibility[steps[newStep].name]) {
      newStep -= 1;
    }

    try {
      const response = (
        await api.http.get(`/prediagnosticos/${currProcesso.id}`)
      ).data;

      let data = {};

      data.prediagnostico = JSON.parse(response.prediagnostico);
      data.processoId = response.processoId;
      data.id = response.id;
      data.currentStep = response.currentStep;
      data.isCompleted = response.isCompleted;

      setPrediagnostico(data);
      setIsAllStepsCompleted(data.isCompleted);

      const dados = {
        ...data.prediagnostico,
        ...steps[newStep].questions.reduce((acc, question) => {
          if (
            question.validation.type === "BOOLEAN" &&
            !data.prediagnostico[question.name]
          ) {
            acc[question.name] = false;
          }

          if (
            question.validation.type === "SELECT" &&
            !data.prediagnostico[question.name]
          ) {
            acc[question.name] = "";
          }

          if (
            question.validation.type === "MULTIPLE_SELECT" &&
            !data.prediagnostico[question.name]
          ) {
            acc[question.name] = [];
          }

          if (
            question.validation.type === "FILE" &&
            !data.prediagnostico[question.name]
          ) {
            acc[question.name] = [];
          }

          if (
            question.validation.type === "RADIO" &&
            !data.prediagnostico[question.name]
          ) {
            acc[question.name] = "";
          }

          if (
            question.validation.type === "TEXT" &&
            !data.prediagnostico[question.name]
          ) {
            acc[question.name] = "";
          }

          if (
            question.validation.type === "TEXTAREA" &&
            !data.prediagnostico[question.name]
          ) {
            acc[question.name] = "";
          }

          if (
            question.validation.type === "DATE" &&
            !data.prediagnostico[question.name]
          ) {
            acc[question.name] = "";
          }

          if (
            question.validation.type === "INTEGER" &&
            !data.prediagnostico[question.name]
          ) {
            acc[question.name] = "";
          }

          if (
            question.validation.type === "DOUBLE" &&
            !data.prediagnostico[question.name]
          ) {
            acc[question.name] = "";
          }

          return acc;
        }, {}),
      };

      handleChangeStep(newStep, stepVisibility);

      Object.keys(dados).forEach((key) => {
        setValue(key, dados[key]);
      });
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNext = (newSteps = stepVisibility) => {
    // handleSave(watch());

    const values = getValues();
    setFormValues((prevValues) => ({ ...prevValues, ...values }));
    let newStep = currentStep + 1;
    while (newStep < steps.length && !newSteps[steps[newStep].name]) {
      newStep += 1;
    }

    if (exceptionStep.includes(steps[newStep].name)) {
      newStep += 1;
    }

    handleChangeStep(newStep, newSteps);
    setLoading(false);
  };

  const handleSaveAndNext = async (data, newSteps = stepVisibility) => {
    setLoading(true);
    const { completed } = await handleIsIsCompleted();

    const allCompleteds = await areAllVisibleStepsCompleted(completed);

    let reportIndex;

    if (allCompleteds) {
      reportIndex = steps.findIndex((step) => step.name === "capex");
    }

    let cleanData = Object.keys(data).reduce((acc, key) => {
      if (data[key] !== undefined && data[key] !== "") {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    try {
      if (!prediagnostico.id) {
        const response = (
          await api.http.post("/prediagnosticos", {
            processo_id: currProcesso.id,
            prediagnostico: cleanData,
            currentStep: reportIndex ?? currentStep,
            isCompleted: isAllStepsCompleted,
          })
        ).data;

        let dataDiagnostico = {};

        dataDiagnostico.prediagnostico = JSON.parse(response.prediagnostico);
        dataDiagnostico.processoId = response.processoId;
        dataDiagnostico.id = response.id;
        dataDiagnostico.currentStep = response.currentStep;
        dataDiagnostico.isCompleted = response.isCompleted;

        setPrediagnostico(dataDiagnostico);

        const dados = {
          ...dataDiagnostico.prediagnostico,
          ...steps[currentStep + 1].questions.reduce((acc, question) => {
            if (
              question.validation.type === "BOOLEAN" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = false;
            }

            if (
              question.validation.type === "SELECT" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = "";
            }

            if (
              question.validation.type === "MULTIPLE_SELECT" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = [];
            }

            if (
              question.validation.type === "FILE" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = [];
            }

            if (
              question.validation.type === "RADIO" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = "";
            }

            if (
              question.validation.type === "TEXT" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = "";
            }

            if (
              question.validation.type === "TEXTAREA" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = "";
            }

            if (
              question.validation.type === "DATE" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = "";
            }

            if (
              question.validation.type === "INTEGER" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = "";
            }

            if (
              question.validation.type === "DOUBLE" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = "";
            }

            return acc;
          }, {}),
        };

        reset(dados);

        const nextVisibleStepIndex = steps.findIndex(
          (step, index) => index > currentStep && stepVisibility[step.name]
        );
        const nextStep = steps[nextVisibleStepIndex];

        if (
          nextStep &&
          (reportNames.includes(nextStep.name) ||
            nextStep.name === "consolidated-results") &&
          !allCompleteds
        ) {
          setStepBlockedDialog(true);
          setLoading(false);
          return;
        } else {
          if (
            steps[currentStep].name === "existing-loads" ||
            steps[currentStep].name === "general-data_3"
          ) {
            handleNext(newSteps);

            return;
          }
          handleNext();
        }
      } else {
        const response = (
          await api.http.put(`/prediagnosticos/${currProcesso.id}`, {
            processo_id: currProcesso.id,
            prediagnostico: cleanData,
            currentStep: reportIndex ?? currentStep,
            isCompleted: isAllStepsCompleted,
          })
        ).data;

        let dataDiagnostico = {};

        dataDiagnostico.prediagnostico = JSON.parse(response.prediagnostico);
        dataDiagnostico.processoId = response.processoId;
        dataDiagnostico.id = response.id;
        dataDiagnostico.currentStep = response.currentStep;
        dataDiagnostico.isCompleted = response.isCompleted;

        setPrediagnostico(dataDiagnostico);

        const dados = {
          ...dataDiagnostico.prediagnostico,
          ...steps[currentStep + 1].questions.reduce((acc, question) => {
            if (
              question.validation.type === "BOOLEAN" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = false;
            }

            if (
              question.validation.type === "SELECT" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = "";
            }

            if (
              question.validation.type === "MULTIPLE_SELECT" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = [];
            }

            if (
              question.validation.type === "FILE" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = [];
            }

            if (
              question.validation.type === "RADIO" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = "";
            }

            if (
              question.validation.type === "TEXT" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = "";
            }

            if (
              question.validation.type === "TEXTAREA" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = "";
            }

            if (
              question.validation.type === "DATE" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = "";
            }

            if (
              question.validation.type === "INTEGER" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = "";
            }

            if (
              question.validation.type === "DOUBLE" &&
              !dataDiagnostico.prediagnostico[question.name]
            ) {
              acc[question.name] = "";
            }

            return acc;
          }, {}),
        };

        reset(dados);

        const nextVisibleStepIndex = steps.findIndex(
          (step, index) => index > currentStep && stepVisibility[step.name]
        );
        const nextStep = steps[nextVisibleStepIndex];

        if (
          nextStep &&
          (reportNames.includes(nextStep.name) ||
            nextStep.name === "consolidated-results") &&
          !allCompleteds
        ) {
          setStepBlockedDialog(true);
          setLoading(false);
          return;
        } else {
          if (
            steps[currentStep].name === "existing-loads" ||
            steps[currentStep].name === "general-data_3"
          ) {
            handleNext(newSteps);

            return;
          }
          handleNext();
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeStep = async (
    index,
    visibilitySteps,
    stepsArray = steps,
    isClickStep = false
  ) => {
    let stepsHide = 0;

    setVisibleItensSteps(false);

    for (let i = 0; i < index; i++) {
      if (!visibilitySteps[stepsArray[i].name]) {
        stepsHide += 1;
      }
    }

    if (isClickStep) {
      try {
        const response = await api.http.get(
          `/prediagnosticos/${currProcesso.id}`
        );
        let data = response.data;

        data.prediagnostico = JSON.parse(data.prediagnostico);
        data.currentStep = index;
        data.isCompleted = data.isCompleted;

        setIsAllStepsCompleted(data.isCompleted);

        const dados = {
          ...data.prediagnostico,
          ...stepsArray[index].questions.reduce((acc, question) => {
            const fieldType = question.validation.type;
            const fieldName = question.name;
            const defaultValue =
              fieldType === "BOOLEAN"
                ? false
                : fieldType === "MULTIPLE_SELECT" || fieldType === "FILE"
                  ? []
                  : "";

            acc[fieldName] = data.prediagnostico[fieldName] ?? defaultValue;
            return acc;
          }, {}),
        };

        await reset(dados);
        setCurrentStep(index);
        setCurrentViewStep(index - stepsHide);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }

      return;
    }

    setCurrentViewStep(index - stepsHide);
    setCurrentStep(index);
    await makeStepItensVisible();
    //aqui que deveria disparar o evento para testar novamente os steps completos


    setLoading(false);
  };

  //Joao Carlos
  //15/05/2024
  const handleCheckboxSystemChange = (checked, name) => {
    if (steps[currentStep] && steps[currentStep].name !== "existing-loads")
      return;

    const previouslyDefined = prediagnostico.prediagnostico[name] || false;
    const currentObs = watch(`${name}_obs`);

    if (checked) {
      if (!previouslyDefined && !currentObs) {
        setModalSystem(name);
      } else {
        setValue(`${name}_obs`, "");
        setModalSystem("");
      }
    } else {
      if (previouslyDefined) {
        setModalSystem(name);
      } else {
        setValue(`${name}_obs`, "");
        setModalSystem("");
      }
    }
  };

  const getInputType = (question) => {
    switch (question.validation.type) {
      case "TEXT":
        return (
          <ContainerInput>
            <Controller
              name={question.name}
              control={control}
              defaultValue={String(question.answer) || ""}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={question.description}
                  error={!!errors[question.name]}
                  helperText={errors[question.name]?.message}
                  disabled={!question.validation.editable}
                  fullWidth
                />
              )}
            />
          </ContainerInput>
        );
      case "TEXTAREA":
        return (
          <ContainerInput>
            <Controller
              name={question.name}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label={question.description}
                  error={!!errors[question.name]}
                  helperText={errors[question.name]?.message}
                  multiline
                  maxRows={5}
                  disabled={isAllStepsCompleted}
                  fullWidth
                />
              )}
            />
          </ContainerInput>
        );
      case "DATE":
        return (
          <ContainerInput>
            <FormLabel>{question.description}</FormLabel>
            <Controller
              name={question.name}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  type="time"
                  //label={question.description}
                  error={!!errors[question.name]}
                  helperText={errors[question.name]?.message}
                  disabled={isAllStepsCompleted}
                  fullWidth
                />
              )}
            />
          </ContainerInput>
        );
      case "INTEGER":
        return (
          <ContainerInput>
            <Controller
              name={question.name}
              control={control}
              defaultValue=""
              rules={{
                max: {
                  value: question.validation.maxValue,
                  message: `Valor máximo é ${question.validation.maxValue}`,
                },
                min: {
                  value: question.validation.minValue,
                  message: `Valor mínimo é ${question.validation.minValue}`,
                },
              }}
              render={({ field }) => (
                <TextField
                  {...field}
                  type="number"
                  label={question.description}
                  error={!!errors[question.name]}
                  helperText={errors[question.name]?.message}
                  disabled={isAllStepsCompleted}
                  fullWidth
                />
              )}
            />
          </ContainerInput>
        );
      case "DOUBLE":
        return (
          <ContainerInput>
            <Controller
              name={question.name}
              control={control}
              defaultValue=""
              rules={{
                max: {
                  value: question.validation.maxValue,
                  message: `Valor máximo é ${question.validation.maxValue}`,
                },
                min: {
                  value: question.validation.minValue,
                  message: `Valor mínimo é ${question.validation.minValue}`,
                },
                validate: (value) => {
                  // Convert the value to a number for validation
                  const numValue = parseFloat(
                    value.replace(/\./g, "").replace(",", ".")
                  );
                  if (numValue > question.validation.maxValue) {
                    return `Valor máximo é ${question.validation.maxValue}`;
                  } else if (numValue < question.validation.minValue) {
                    return `Valor mínimo é ${question.validation.minValue}`;
                  }
                  return true;
                },
              }}
              render={({
                field: { ref, onChange, ...field },
                fieldState: { error },
              }) => (
                <NumberFormat
                  {...field}
                  getInputRef={ref}
                  customInput={TextField}
                  label={question.description}
                  error={!!error}
                  helperText={error ? error.message : null}
                  fullWidth
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale={true}
                  prefix={question.validation?.mask === "currency" ? "R$ " : ""}
                  onValueChange={(values) => {
                    onChange(values.value);
                  }}
                  isNumericString
                  allowNegative={false}
                  disabled={isAllStepsCompleted}
                />
              )}
            />
          </ContainerInput>
        );

      case "SELECT":
        const isDisabledSelect =
          (steps[currentStep]?.name === "general-data_1" &&
            prediagnostico.prediagnostico[question.name] !== undefined) ||
          isAllStepsCompleted;

        return (
          <ContainerInput>
            <Controller
              name={question.name}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl fullWidth disabled={isDisabledSelect}>
                  <InputLabel>{question.description}</InputLabel>
                  <Select
                    {...field}
                    label={question.description}
                    error={!!errors[question.name]}
                  >
                    {question.name === "general-data_1_4"
                      ? subsegmentOptions.length > 0 &&
                      subsegmentOptions.map((option, index) => (
                        <MenuItem key={index} value={option.key}>
                          {option.value}
                        </MenuItem>
                      ))
                      : question.validation.options?.map((option, index) => (
                        <MenuItem key={index} value={option.key}>
                          {option.value}
                        </MenuItem>
                      ))}
                  </Select>
                  {question.name === "general-data_1_4" && (
                    <FormHelperText error={!!errors[question.name]}>
                      {errors[question.name]?.message}
                    </FormHelperText>
                  )}

                  {question.name === "general-data_1_3" &&
                    watch(question.name) && (
                      <FormHelperText>
                        Após clicar no botão continuar o Segmento Industrial não
                        pode mais ser alterado
                      </FormHelperText>
                    )}

                  {question.name === "general-data_1_4" &&
                    watch(question.name) && (
                      <FormHelperText>
                        Após clicar no botão continuar o Subsegmento Industrial
                        não pode mais ser alterado
                      </FormHelperText>
                    )}
                </FormControl>
              )}
            />
          </ContainerInput>
        );
      case "MULTIPLE_SELECT":
        return (
          <ContainerInput>
            <Controller
              name={question.name}
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <FormControl fullWidth disabled={isAllStepsCompleted}>
                  <InputLabel>{question.description}</InputLabel>
                  <Select
                    {...field}
                    multiple
                    label={question.description}
                    error={!!errors[question.name]}
                    value={Array.isArray(field.value) ? field.value : []}
                  >
                    {question.validation.options?.map((option, index) => (
                      <MenuItem key={index} value={option.key}>
                        {option.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </ContainerInput>
        );
      case "BOOLEAN":
        return (
          <ContainerInput>
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                justifyContent: "space-between",
              }}
            >
              <Controller
                name={question.name}
                control={control}
                defaultValue={undefined}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <>
                        <Checkbox
                          {...field}
                          checked={
                            typeof field.value === "boolean"
                              ? field.value
                              : false
                          }
                          disabled={isAllStepsCompleted}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            handleCheckboxSystemChange(
                              e.target.checked,
                              question.name
                            );
                          }}
                        />
                      </>
                    }
                    label={question.description}
                  />
                )}
              />

              {watch(`${question.name}_obs`) && (
                <Button
                  onClick={() => {
                    setModalSystemObs(question.name);
                  }}
                >
                  <InfoIcon />
                </Button>
              )}
            </Box>
          </ContainerInput>
        );
      case "RADIO":
        const isDisabled =
          steps[currentStep]?.name === "steam-boilers_2" &&
          steamBoilersData[question.name];

        return (
          <ContainerInput>
            <Controller
              name={question.name}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <FormControl>
                  <FormLabel component="legend">
                    {question.description}
                  </FormLabel>
                  <RadioGroup
                    {...field}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      value={1}
                      control={
                        <Radio disabled={isDisabled || isAllStepsCompleted} />
                      }
                      label="Sim"
                    />

                    <FormControlLabel
                      value={0}
                      control={
                        <Radio disabled={isDisabled || isAllStepsCompleted} />
                      }
                      label="Não"
                    />

                    {question.validation.thirdOption && (
                      <FormControlLabel
                        value={2}
                        control={
                          <Radio disabled={isDisabled || isAllStepsCompleted} />
                        }
                        label={question.validation.thirdOption}
                      />
                    )

                    }

                  </RadioGroup>
                </FormControl>
              )}
            />
          </ContainerInput>
        );

      case "FILE":
        return (
          <ContainerInput>
            <Controller
              name={question.name}
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <FileListFieldPreDiagnostico
                  field={field}
                  setValue={setValue}
                  errors={errors}
                  label={question.description}
                  initialFiles={field.value || []}
                  disabled={isAllStepsCompleted}
                />
              )}
            />
          </ContainerInput>
        );

      default:
        return null;
    }
  };

  async function handleIsIsCompleted(
    stepIndex = currentStep,
    stepsArray = steps,
    questionsOff = invisibleQuestions
  ) {
    const step = stepsArray[stepIndex];

    if (
      !step.isSubstep &&
      step.name !== "existing-loads" &&
      step.name !== "general-data_3"
    )
      return null;

    const questions = step.questions.filter((question) => {
      const isOffInInvisibleQuestions = questionsOff[question.name];
      const isOffInVisibilityQuestions = questionVisibility.includes(
        question.name
      );
      return !isOffInInvisibleQuestions && !isOffInVisibilityQuestions;
    });

    let isCompleted =
      step.name === "existing-loads" || step.name === "general-data_3"
        ? questions.some((question) => isQuestionCompleted(question))
        : questions.every((question) => isQuestionCompleted(question));

    let newCompletedSteps = completedSteps;
    let newIncompleteSteps = incompleteSteps;

    if (isCompleted) {
      if (!newCompletedSteps.includes(step.name)) {
        newCompletedSteps.push(step.name);
      }
      newIncompleteSteps = newIncompleteSteps.filter(
        (name) => name !== step.name
      );
    } else {
      if (!newIncompleteSteps.includes(step.name)) {
        newIncompleteSteps.push(step.name);
      }
      newCompletedSteps = newCompletedSteps.filter(
        (name) => name !== step.name
      );
    }

    setCompletedSteps(newCompletedSteps);
    setIncompleteSteps(newIncompleteSteps);

    return {
      completed: newCompletedSteps,
      incomplete: newIncompleteSteps,
    };
  }

  function isQuestionCompleted(question) {
    const value = watch(question.name);
    switch (question.validation.type) {
      case "BOOLEAN":
        return value === true;
      case "SELECT":
      case "TEXT":
      case "TEXTAREA":
      case "DATE":
        return value !== "" && value !== null && value !== undefined;
      case "MULTIPLE_SELECT":
        return Array.isArray(value) && value.length > 0;
      case "FILE":
        return (
          !question.validation.required ||
          (Array.isArray(value) && value.length > 0)
        );
      case "INTEGER":
      case "DOUBLE":
        return value !== "" && value !== null && value !== undefined;
      case "RADIO":
        return value !== "" && value !== null && value !== undefined;
      default:
        return false;
    }
  }

  async function handleSave(data) {
    startLoading();

    let cleanData = Object.keys(data).reduce((acc, key) => {
      if (data[key] !== undefined && data[key] !== "") {
        acc[key] = data[key];
      }
      return acc;
    }, {});

    try {
      if (!prediagnostico.id) {
        const response = (
          await api.http.post("/prediagnosticos", {
            processo_id: currProcesso.id,
            prediagnostico: cleanData,
            currentStep: currentStep,
            isCompleted: false,
          })
        ).data;
        stopLoading();

        let dataDiagnostico = {};

        dataDiagnostico.prediagnostico = JSON.parse(response.prediagnostico);
        dataDiagnostico.processoId = response.processoId;
        dataDiagnostico.id = response.id;
        dataDiagnostico.currentStep = response.currentStep;

        setPrediagnostico(dataDiagnostico);
      } else {
        const response = (
          await api.http.put(`/prediagnosticos/${currProcesso.id}`, {
            processo_id: currProcesso.id,
            prediagnostico: cleanData,
            currentStep: currentStep,
            isCompleted: false,
          })
        ).data;
        stopLoading();

        let dataDiagnostico = {};

        dataDiagnostico.prediagnostico = JSON.parse(response.prediagnostico);
        dataDiagnostico.processoId = response.processoId;
        dataDiagnostico.id = response.id;
        dataDiagnostico.currentStep = response.currentStep;

        setPrediagnostico(dataDiagnostico);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const onSubmit = (data) => {
    handleSave(data);
  };

  const handleStepClick = async (index) => {
    if (
      !prediagnostico.prediagnostico["general-data_1_3"] ||
      !prediagnostico.prediagnostico["general-data_1_4"]
    ) {
      setError("general-data_1_4", {
        type: "manual",
        message: "Subsegmento é um campo obrigatório para prosseguir",
      });
      return;
    }

    const allStepsCompleted = await areAllVisibleStepsCompleted();

    if (reportNames.includes(steps[index].name) && !allStepsCompleted) {
      setStepBlockedDialog(true);
      return;
    }

    setLoading(true);
    handleChangeStep(
      exceptionStep.includes(steps[index].name) ? index + 1 : index,
      stepVisibility,
      undefined,
      true
    );
    scroller.scrollTo("myScrollToElement", {
      duration: 100,
      delay: 50,
      smooth: true,
      offset: 0,
    });
  };

  const areAllVisibleStepsCompleted = async (completed = completedSteps) => {
    let visibleSteps = steps.filter(
      (step) => step.isSubstep || step.name === "existing-loads"
    );

    visibleSteps = visibleSteps.filter((step) => stepVisibility[step.name]);

    visibleSteps = visibleSteps.filter(
      (step) => !reportNames.includes(step.name)
    );

    const isAllCompleted = visibleSteps.every((step) =>
      completed.includes(step.name)
    );

    // if (isAllCompleted) {
    //   reportNames.forEach((name) => {
    //     if (!completed.includes(name)) {
    //       setCompletedSteps((prev) => [...prev, name]);
    //     }
    //   });
    // }

    return isAllCompleted;
  };

  const handleContinueClick = async () => {
    if (!watch("general-data_1_3") || !watch("general-data_1_4")) {
      setError("general-data_1_4", {
        type: "manual",
        message: "Subsegmento é um campo obrigatório para prosseguir",
      });
      return;
    }

    scroller.scrollTo("myScrollToElement", {
      duration: 100,
      delay: 50,
      smooth: true,
      offset: 0,
    });

    if (
      steps[currentStep].name === "general-data_1" &&
      !watch("general-data_1_4")
    ) {
      setError("general-data_1_4", {
        type: "manual",
        message: "Subsegmento é um campo obrigatório para prosseguir",
      });
      return;
    }

    if (
      steps[currentStep].name === "existing-loads" ||
      steps[currentStep].name === "general-data_3"
    ) {
      const updatedSteps = updateStepsVisibility();

      if (updatedSteps) handleSaveAndNext(watch(), updatedSteps);
    } else {
      await handleSaveAndNext(watch());
    }
  };

  return (
    <div>
      <Dialog
        open={loadQuestions.includes(modalSystemObs)}
        onClose={() => setModalSystemObs("")}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          width: "100%",
        }}
      >
        <DialogTitle id="alert-dialog-title">
          Motivo da remoção de {modalSystemObs}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {watch(`${modalSystemObs}_obs`)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalSystemObs("")}>Fechar</Button>
        </DialogActions>
      </Dialog>
      <Box
        display="flex"
        flexDirection={matches ? "row" : "column"}
        gap={4}
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: matches ? "300px" : "100%",
            flexShrink: 0,
            padding: "25px",
            overflowX: "auto",
          }}
          className="container"
        >
          <Stepper
            activeStep={currentViewStep}
            orientation={matches ? "vertical" : "horizontal"}
          >
            {steps.map(
              (step, index) =>
                stepVisibility[step.name] && (
                  <Step
                    completed={
                      completedSteps.includes(step.name) ||
                      (activeReports && reportNames.includes(step.name))
                    }
                    key={index}
                    onClick={() => handleStepClick(index)}
                  >
                    <StepLabel
                      error={incompleteSteps.includes(step.name)}
                      optional={
                        incompleteSteps.includes(step.name) && (
                          <Typography variant="caption" color="error">
                            Incompleto
                          </Typography>
                        )
                      }
                      sx={{
                        ".MuiStepLabel-label": {
                          fontSize: step.isSubstep ? "12px" : "16px",
                          marginLeft: step.isSubstep ? "5px" : "2px",
                          color:
                            step.name === "general-data"
                              ? "#62bb46"
                              : step.name === "existing-loads"
                                ? "#62bb46"
                                : step.name === "consolidated-results"
                                  ? "#62bb46"
                                  : index === currentStep
                                    ? "black"
                                    : "", // Default color for other steps
                          fontWeight: index === currentStep ? "bold" : "normal",
                        },
                        ".MuiSvgIcon-root": {
                          fontSize: step.isSubstep ? "0.875rem" : "1.25rem",
                          marginLeft: step.isSubstep ? "5px" : "2px",
                          color:
                            step.name === "general-data"
                              ? "#62bb46"
                              : step.name === "existing-loads"
                                ? "#62bb46"
                                : step.name === "consolidated-results"
                                  ? "#62bb46"
                                  : "", // Default color for other icons
                        },
                      }}
                    >
                      {step.description}
                    </StepLabel>
                  </Step>
                )
            )}
          </Stepper>
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: matches ? "calc(100% - 300px)" : "100%",
            overflowX: "auto",
            flexShrink: 1,
          }}
        >
          {loading > 0 && (
            <Skeleton
              variant="rectangular"
              sx={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 1,
              }}
            />
          )}

          {steps[currentStep] &&
            !stepsReportsNames.includes(steps[currentStep].name) &&
            !loading && (
              <form className="container">
                <Element name="myScrollToElement">
                  <h3>{steps[currentStep].description}</h3>
                  <Box
                    id="ContainerElementID"
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                      width: "100%",
                    }}
                  >
                    {steps[currentStep].questions?.map((question, index) => {
                      let shouldShow = false;

                      switch (steps[currentStep].name) {
                        case "general-data_2":
                        case "steam-boilers_2":
                          shouldShow = !questionVisibility.includes(
                            question.name
                          );
                          break;

                        case "general-data_7":
                        case "general-data_8":
                        case "general-data_9":
                        case "general-data_10":
                          shouldShow = (question.name !== "general-data_8_6" && question.name !== "general-data_9_5") ? shouldShowField(
                            question.name,
                            watch,
                            setValue,
                            configVisibility,
                            steps[currentStep]
                          ) : !questionVisibility.includes(
                            question.name
                          );
                          break;

                        case "oven-hearters_2":
                          shouldShow = !questionVisibility.includes(
                            question.name
                          );
                          break;

                        case "refrigeration_2":
                          shouldShow = !questionVisibility.includes(
                            question.name
                          );
                          break;

                        default:
                          shouldShow = true;
                      }

                      return (
                        shouldShow && (
                          <div key={index} style={{ width: "100%" }}>
                            {getInputType(question)}
                          </div>
                        )
                      );
                    })}
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: matches ? "row" : "column",
                      gap: 2,
                      width: "100%",
                      padding: 2,
                    }}
                  >
                    {steps[currentStep].name === "steam-boilers_2" && (
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          scroller.scrollTo("myScrollToElement", {
                            duration: 100,
                            delay: 50,
                            smooth: true,
                            offset: 0,
                          });

                          setResetSteamBoilersQuestion(true);
                          setQuestionVisibility(steamBoilersQuestionNames);

                          steamBoilersQuestionNames.forEach((names) => {
                            setValue(names, "");
                          });

                          setValue("steam-boilers_2_1", "");
                        }}
                        sx={{
                          flex: 1,
                          padding: "10px",
                          marginTop: matches ? 2 : 0,
                        }}
                      >
                        Reiniciar
                      </Button>
                    )}
                    {currentStep !== 1 && (
                      <Button
                        variant="outlined"
                        onClick={() => {
                          scroller.scrollTo("myScrollToElement", {
                            duration: 100,
                            delay: 50,
                            smooth: true,
                            offset: 0,
                          });
                          handleBack();
                        }}
                        sx={{
                          flex: matches ? 1 : "none",
                          width: matches ? "auto" : "100%",
                          padding: "10px",
                          marginTop: matches ? 2 : 0,
                        }}
                      >
                        Voltar
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      disabled={Object.keys(errors).length > 0}
                      onClick={handleSubmit(onSubmit)}
                      sx={{
                        flex: 1,
                        padding: "10px",
                        marginTop: matches ? 2 : 0,
                      }}
                    >
                      Salvar
                    </Button>
                    <Button
                      disabled={Object.keys(errors).length > 0}
                      variant="contained"
                      onClick={() => handleContinueClick()}
                      sx={{
                        flex: 1,
                        padding: "10px",
                        width: matches ? "100%" : "auto",
                        marginTop: matches ? 2 : 0,
                      }}
                    >
                      Continuar
                    </Button>
                  </Box>

                  {loadQuestions.map((loads, index) => {
                    return (
                      <Dialog
                        key={index}
                        open={modalSystem === loads}
                        onClose={() => setModalSystem("")}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        <DialogTitle id="alert-dialog-title">
                          Por qual motivo você deseja{" "}
                          {watch(loads) ? "adicionar" : "remover"}{" "}
                        </DialogTitle>
                        <DialogContent>
                          <DialogContentText id="alert-dialog-description">
                            <Controller
                              key={index}
                              name={`${loads}_obs`}
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                  }}
                                  label="Motivo"
                                  multiline
                                  rows={4}
                                  fullWidth
                                />
                              )}
                            />
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            onClick={() => {
                              setModalSystem("");
                              setValue(`${loads}_obs`, "");
                              setValue(loads, !watch(loads));
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button onClick={() => setModalSystem("")} autoFocus>
                            Salvar
                          </Button>
                        </DialogActions>
                      </Dialog>
                    );
                  })}
                </Element>
              </form>
            )}

          <Dialog
            open={stepBlockedDialog}
            onClose={() => setStepBlockedDialog(false)}
          >
            <DialogTitle>Atenção</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Por favor, complete todos os passos anteriores antes de
                prosseguir para os relatórios.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setStepBlockedDialog(false)}>
                Fechar
              </Button>
            </DialogActions>
          </Dialog>

          {steps[currentStep] && steps[currentStep].name === "capex" && (
            <StepPreDiagnosticoRelCapex
              jsonData={DataJSON}
              jsonBanco={prediagnostico}
              currProcesso={currProcesso}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )}

          {steps[currentStep] && steps[currentStep].name === "nocapex" && (
            <StepPreDiagnosticoRelNaoCapex
              jsonData={DataJSON}
              jsonBanco={prediagnostico}
              currProcesso={currProcesso}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          )}

          {steps[currentStep] && steps[currentStep].name === "outrasAcoes" && (
            <StepPreDiagnosticoRelOutrasAcoes
              jsonData={DataJSON}
              jsonBanco={prediagnostico}
              currProcesso={currProcesso}
              handleBack={handleBack}
              openModal={openModal}
            />
          )}
          <StepPreDiagnosticoModalRelatorioResumo
            isOpen={isModalOpen}
            onClose={closeModal}
            jsonData={DataJSON}
            jsonBanco={prediagnostico}
            currProcesso={currProcesso}
          />
        </Box>
      </Box>
    </div>
  );
}
