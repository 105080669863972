import React, { useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
} from "@mui/material";
import './ModalRelResumo.css';
import { formatCurrency, formatInteger, formatTime } from '../../services/utils';

const RelatorioResultadoEnergiaEletrica = ({ lstQuestionario, lstRespostas }) => {
  const [listEnergiaEletrica, setListEnergiaEletrica] = React.useState([]);

  useEffect(() => {
    setListEnergiaEletrica(getQuestionsAndAnswers('general-data_2'));
  }, []);

  //método para retornar o valor para o relatorio de um select
  const getValueFromOptions = (questionName, answerValue) => {
    let valueFromOptions = null;
    lstQuestionario.steps.forEach((step) => {
      if (step.groups) {
        step.groups?.forEach((group) => {
          if (group.questions) {
            group.questions.forEach((question) => {
              if (question.name === questionName) {
                question.validation.options.forEach((options) => {
                  if (options.key === answerValue.toString()) {
                    valueFromOptions = options.value;
                  };
                });
              };
            });
          };
        });
      };
    });
    return valueFromOptions;
  };

  // Função para obter a lista de perguntas e respostas de um grupo específico
  const getQuestionsAndAnswers = (groupName) => {
    const questionsAndAnswers = [];
    lstQuestionario.steps.forEach((step) => {
      step.groups?.forEach((group) => {
        if (group.name === groupName) {
          group.questions?.forEach((question) => {
            if (lstRespostas.prediagnostico[question.name]) {
              questionsAndAnswers.push({
                questionName: question.name,
                questionDescription: question.description,
                questionAnswer: question.answer,
                questionValidation: question.validation.type,
                questionMask: question.validation?.mask,
                questionThirdOption: question.validation?.thirdOption
              });
            };
          });
        };
      });
    });



    return questionsAndAnswers;
  };

  return (
    <>
      <Box className="title">
        <Typography variant="h4" gutterBottom>
          ENERGIA ELÉTRICA:
        </Typography>
      </Box>
      <Grid container spacing={0} className="grid-container">
        {listEnergiaEletrica.map((item, index) => (
          <><Grid item xs={4} className="item-response">
            <h4>{item.questionDescription}: </h4>
          </Grid>
            <Grid item xs={8} className="item-response">

              <h4>{
                item.questionValidation === 'RADIO' ? (
                  parseInt(lstRespostas.prediagnostico[item.questionName]) === 1 ? 'Sim' :
                    parseInt(lstRespostas.prediagnostico[item.questionName]) === 0 ? 'Não' :
                      item?.questionThirdOption
                ) : (
                  item.questionValidation === 'SELECT' ?
                    getValueFromOptions(item.questionName, lstRespostas.prediagnostico[item.questionName]) :
                    (
                      item.questionValidation === 'DOUBLE' ?
                        item.questionMask === 'currency' ?
                          formatCurrency(lstRespostas.prediagnostico[item.questionName])
                          : formatInteger(lstRespostas.prediagnostico[item.questionName])
                        : lstRespostas.prediagnostico[item.questionName]
                    )
                )
              }</h4>
            </Grid></>

        ))}
      </Grid>
    </>
  );
};

export default RelatorioResultadoEnergiaEletrica;