import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import api from "../../services/api";

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DialogoVisualizador = ({ file, open, onClose }) => {
  const [url, setUrl] = useState(null);

  const src = async () => {
    if (!file) return;

    try {
      const response = await api.http.get(
        `/prediagnosticos/${file.id}/getsignedaws`
      );

      setUrl(response.data.fileRef);
    } catch (error) {
      console.error("Erro ao buscar arquivo:", error);
    }
  };

  useEffect(() => {
    if (file) {
      src();
    }
  }, [file]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth={true}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={onClose}>
        {file?.fileName}
      </BootstrapDialogTitle>
      <DialogContent className="file-viewer">
        <img src={url} className="img-responsive" />
      </DialogContent>
    </Dialog>
  );
};

export default DialogoVisualizador;
