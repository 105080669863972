import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Divider, Grid, Typography, Button, useMediaQuery } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useEffect, useState } from "react";
import { styled } from '@mui/system';

export default function StepPreDiagnosticoRelCapex({ currProcesso, jsonData, jsonBanco, handleBack, handleNext }) {
  const [cargas, setCargas] = React.useState(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(async () => {
    setCargas(await listaCargasSelecionadas());
  }, []);

  const listaCargasSelecionadas = () => {
    const groupsAndAnswers = [];
    jsonData.steps.forEach((step) => {
      if (step.name === "existing-loads") {
        step.questions?.forEach((question) => {
          if (
            question.name !== "existing-loads_10" &&
            jsonBanco.prediagnostico[question.name] === true
          ) {
            groupsAndAnswers.push({
              Name: question.existingLoadsNames,
              Description: question.description,
            });
          }
        });
      }
    });
    return groupsAndAnswers;
  };

  const countQuestionsExistingLoads = (groupName) => {
    const groupsAndAnswers = [];
    let contador = 0;
    jsonData.steps.forEach((step) => {
      if (step.name === "existing-loads") {
        step.groups?.forEach((group) => {
          if (group.name === groupName) {
            group.questions?.forEach((question) => {
              if (
                question.capex === true
                && question.validation.type !== "FILE"
                && question.energyEficiencySugestion !== ""
                && ((question.reportSugest === true ? 1 : 0) === parseInt(jsonBanco.prediagnostico[question.name]))
              ) {
                const exists = groupsAndAnswers.some(
                  (item) => item.energyEficiencySugestion === question.energyEficiencySugestion
                );
                // if (question.name === "steam-boilers_2_32") {
                //   console.log("Question ", question);
                // }

                // if (question.name === "steam-boilers_2_33") {
                //   console.log("Question 33 ", question);
                // }
                if (question.name === "steam-boilers_2_32" || question.name === "steam-boilers_2_33") {
                  if (jsonBanco.prediagnostico["steam-boilers_2_32"] === 1 &&
                    jsonBanco.prediagnostico["steam-boilers_2_33"] === 1) {
                    if (!exists && jsonBanco.prediagnostico[question.name] !== undefined) {
                      groupsAndAnswers.push({
                        name: question.name,
                        description: question.description,
                        observations: question.observations,
                        energyEficiencySugestion: question.energyEficiencySugestion,
                      });
                      contador += 1;
                    }
                  }
                }
                else {
                  if (!exists && jsonBanco.prediagnostico[question.name] !== undefined) {
                    groupsAndAnswers.push({
                      name: question.name,
                      description: question.description,
                      observations: question.observations,
                      energyEficiencySugestion: question.energyEficiencySugestion,
                    });
                    contador += 1;
                  }
                }
              }
            });
          }
        });
      }
    });
    return contador;
  };

  const retornaQuestionsExistingLoads = (groupName) => {
    const groupsAndAnswers = [];
    jsonData.steps.forEach((step) => {
      if (step.name === "existing-loads") {
        step.groups?.forEach((group) => {
          if (group.name === groupName) {
            group.questions?.forEach((question) => {
              if (
                question.capex === true
                && question.validation.type !== "FILE"
                && question.energyEficiencySugestion !== ""
                && ((question.reportSugest === true ? 1 : 0) === parseInt(jsonBanco.prediagnostico[question.name]))
              ) {
                const exists = groupsAndAnswers.some(
                  (item) => item.energyEficiencySugestion === question.energyEficiencySugestion
                );
                if (!exists && jsonBanco.prediagnostico[question.name] !== undefined) {
                  groupsAndAnswers.push({
                    name: question.name,
                    description: question.description,
                    observations: question.observations,
                    energyEficiencySugestion: question.energyEficiencySugestion,
                  });
                };
              }
            });
          };

        });
      }
    });

    return groupsAndAnswers;
  };

  const HyphenatedText = styled(Typography)(({ theme }) => ({
    hyphens: 'auto',
    wordBreak: 'break-word',
    WebkitHyphens: 'auto',
    MozHyphens: 'auto',
    textAlign: 'justify',
  }));

  const montaHtmlAccordion = (carga, idx) => {
    const contador = countQuestionsExistingLoads(carga.Name);
    const listaCapex = retornaQuestionsExistingLoads(carga.Name);
    return (
      <>
        <Accordion disabled={contador === 0 ? true : false}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel{idx + 1}-content"
            id="panel{idx + 1}-header"
            sx={{
              ...(isMobile && {
                padding: "8px",
                '& .MuiTypography-root': {
                  fontSize: '0.675rem',
                },
              }),
            }}
          >
            <Grid container spacing={2} className={contador === 0 ? 'collapse-disabled' : ''}>
              <Grid item xs={8} sx={{ textAlign: "left" }}>
                {/* <body1>{carga.Description}</body1> */}
                <Typography variant="body1">{carga.Description}</Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{ textAlign: "right", paddingRight: "1.5rem" }}
              >
                {/* <body1>{contador} ações sugeridas</body1> */}
                <Typography variant="body1">{contador} ações sugeridas</Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              ...(isMobile && {
                padding: "8px",
                '& .MuiTypography-root': {
                  fontSize: '0.875rem',
                },
              }),
            }}
          >
            {listaCapex.map((it, index) => (
              <>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    // textAlign: "justify",
                    paddingLeft: "0.5rem",
                    paddingRight: "0.5rem",
                  }}
                >
                  {index + 1}. {it.energyEficiencySugestion}
                </Typography>

                <Box sx={{ padding: 0.5 }}>
                  <HyphenatedText variant="body2">
                    {it.observations}
                  </HyphenatedText>
                </Box>

                {/* <Typography
                  variant="body2"
                  gutterBottom
                  sx={{
                    // textAlign: "justify",
                    paddingLeft: "1rem",
                    paddingRight: "0.5rem",
                    paddingBottom: "0.8rem",
                  }}
                >
                  {it.observations}
                </Typography> */}
              </>
            ))}

          </AccordionDetails>
        </Accordion>

      </>
    );
  };

  return (
    <Box
      className="container"
      sx={{
        padding: "25px",
      }}
    >
      <Divider>
        <h3>Relatório CAPEX</h3>
      </Divider>
      {cargas && cargas.length > 0
        ? cargas.map((item, index) => <>
          {montaHtmlAccordion(item, index)}
        </>)
        : null}
      <Button
        variant="outlined"
        onClick={handleBack}
        sx={{
          flex: "none",
          width: "40%",
          padding: "10px",
          marginTop: 2,
          marginRight: "1rem",
        }}
      >
        Voltar
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          handleNext();
        }}
        sx={{
          flex: 1,
          padding: "10px",
          width: "40%",
          marginTop: 2,
        }}
      >
        Continuar
      </Button>
    </Box>
  )
}