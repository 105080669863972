import React, { useEffect, useState, useRef } from 'react';
import Button from '@mui/material/Button';
import StepPreDiagnosticoRelResultado from './StepPreDiagnosticoRelResultado';
import './StepPreDiagnosticoRelatorios/ModalRelResumo.css';
import { ReactToPrint } from 'react-to-print';
import Dialog from '@mui/material/Dialog';
import { DialogTitle, DialogContent, DialogActions, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Slide from '@mui/material/Slide';
import {
  Paper,
} from "@mui/material";


const StepPreDiagnosticoModalRelatorioResumo = ({ isOpen, onClose, currProcesso, jsonData, jsonBanco }) => {

  const modalRef = useRef(null);
  const handlePrint = (e) => {
    e.preventDefault();
    //console.log("inicio impressão ", currProcesso);
    const printArea = modalRef.current;
    const printLogo = document.createElement('div');
    const logoImage = document.createElement('img');
    const originalTitle = document.title; // Guarda o título original

    if (modalRef.current) {
      printLogo.className = 'print-logo';
      logoImage.src = 'https://potencializee.develop.pumpkintech.com.br/logo.png';
      logoImage.alt = 'Potencializze';
      printLogo.appendChild(logoImage);
      document.body.appendChild(printLogo);

      // Define o título temporário para o nome do arquivo
      document.title = "Pré-Diagnostico - " + currProcesso.razaoSocial;

      // Executa a impressão
      window.print();

      // Remove o logo e restaura o título original
      document.body.removeChild(printLogo);
      document.title = originalTitle;
    }
    //   e.preventDefault();
    //   const printArea = modalRef.current;
    //   const printLogo = document.createElement('div');
    //   const logoImage = document.createElement('img');
    //   const originalTitle = document.title;
    //   if (modalRef.current) {
    //     printLogo.className = 'print-logo';
    //     logoImage.src = 'https://potencializee.develop.pumpkintech.com.br/logo.png';
    //     logoImage.alt = 'Potencializze';
    //     printLogo.appendChild(logoImage);
    //     document.body.appendChild(printLogo);
    //     document.title = "Pré-Diagnostico - " + currProcesso.nome;
    //     window.print();
    //     document.body.removeChild(printLogo);
    //     document.timeline = originalTitle;
    //   }

  };

  return (

    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
    >

      <DialogContent className="print-container">
        <div ref={modalRef}>
          <Paper
            variant="outlined"
            className="center-box"
            sx={{
              textAlign: "center",
              px: 4,
              pt: 2,
              pb: 3,
              maxWidth: "md",
            }}
          >
            <StepPreDiagnosticoRelResultado
              jsonData={jsonData}
              jsonBanco={jsonBanco}
              currProcesso={currProcesso} />
          </Paper>
        </div>

      </DialogContent>
      <DialogActions className='print-hidden'>
        {/* <Button onClick={handleExport}>Exportar</Button> */}

        <Button onClick={handlePrint}>Imprimir</Button>

        <Button onClick={onClose}>Fechar</Button>
      </DialogActions>
    </Dialog>

  );
};

export default StepPreDiagnosticoModalRelatorioResumo;




// import React, { useEffect, useState, useRef } from 'react';
// import Button from '@mui/material/Button';
// import StepPreDiagnosticoRelResultado from './StepPreDiagnosticoRelResultado';
// import './StepPreDiagnosticoRelatorios/ModalRelResumo.css';
// import { ReactToPrint } from 'react-to-print';
// import Dialog from '@mui/material/Dialog';
// import { DialogTitle, DialogContent, DialogActions, Box, Paper } from '@mui/material';
// import IconButton from '@mui/material/IconButton';
// import CloseIcon from '@mui/icons-material/Close';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Typography from '@mui/material/Typography';
// import List from '@mui/material/List';
// import Divider from '@mui/material/Divider';
// import ListItemText from '@mui/material/ListItemText';
// import ListItemButton from '@mui/material/ListItemButton';
// import Slide from '@mui/material/Slide';
// import html2canvas from "html2canvas";
// import jsPDF from "jspdf";
// import { useStoreActions } from "easy-peasy";


// const StepPreDiagnosticoModalRelatorioResumo = ({ isOpen, onClose, currProcesso, jsonData, jsonBanco }) => {
//   const modalRef = useRef(null);
//   const chartRef = React.useRef(null);

//   let startLoading = useStoreActions((actions) => actions.nav.startLoading);
//   let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

//   const handlePrint = (e) => {
//     e.preventDefault();
//     const printArea = modalRef.current;
//     const printLogo = document.createElement('div');
//     const logoImage = document.createElement('img');
//     const originalTitle = document.title;
//     if (modalRef.current) {
//       printLogo.className = 'print-logo';
//       logoImage.src = 'https://potencializee.develop.pumpkintech.com.br/logo.png';
//       logoImage.alt = 'Potencializze';
//       printLogo.appendChild(logoImage);
//       document.body.appendChild(printLogo);
//       document.title = "Pré-Diagnostico - " + currProcesso.nome;
//       window.print();
//       document.body.removeChild(printLogo);
//       document.timeline = originalTitle;
//     }

//   };

//   // const exportar = async (data) => {
//   //   //startLoading();

//   //   try {
//   //     let elem = chartRef.current;

//   //     let canvas = await html2canvas(elem, {
//   //       backgroundColor: "#fff",
//   //       allowTaint: false,
//   //       scale: 90,
//   //     });
//   //     let image = canvas.toDataURL("image/png");
//   //     const pdf = new jsPDF("portrait", "px", "a4");

//   //     pdf.addImage(
//   //       image,
//   //       "PNG",
//   //       pdf.internal.pageSize.getWidth() / 2.0 - 105,
//   //       0,
//   //       210,
//   //       pdf.internal.pageSize.getHeight()
//   //     );

//   //     pdf.save(`${currProcesso.nome}-${new Date().toISOString()}.pdf`);
//   //     //stopLoading();
//   //   } catch (error) {
//   //     //  // stopLoading({
//   //     //     message: error.toString(),
//   //     //     severity: "error",
//   //     //   });
//   //   }
//   // };

//   return (

//     <Dialog
//       fullScreen
//       open={isOpen}
//       onClose={onClose}
//     >

//       <DialogContent className="print-container">
//         {/* <div ref={chartRef}>
//           <Paper
//             variant="outlined"
//             className="center-box"
//             sx={{
//               textAlign: "center",
//               px: 4,
//               pt: 2,
//               pb: 3,
//               m: 5,
//             }}
//           > */}
//         <StepPreDiagnosticoRelResultado
//           jsonData={jsonData}
//           jsonBanco={jsonBanco}
//           currProcesso={currProcesso} />
//         {/* </Paper>

//         </div> */}
//       </DialogContent>
//       <DialogActions className='print-hidden'>
//         {/* <Button
//           variant="contained"
//           onClick={exportar}
//         //disabled={Object.keys(datasets).length === 0}
//         >
//           exportar
//         </Button> */}
//         <Button onClick={handlePrint}>Imprimir</Button>

//         <Button onClick={onClose}>Fechar</Button>
//       </DialogActions>
//     </Dialog>

//   );
// };

// export default StepPreDiagnosticoModalRelatorioResumo;
