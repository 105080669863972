import ImageIcon from "@mui/icons-material/Image";
import { FormHelperText, Link } from "@mui/material";
import { useStoreActions } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { DropzoneAreaBase } from "react-mui-dropzone";

import api from "../../services/api";
import DialogoVisualizador from "./DialogoVisualizador";

const FileListFieldPreDiagnostico = ({
  field,
  setValue,
  errors,
  label,
  initialFiles,
  disabled,
}) => {
  const [files, setFiles] = useState(initialFiles || []);
  const [openDlgVis, setOpenDlgVis] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const startLoading = useStoreActions((actions) => actions.nav.startLoading);
  const stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

  // Atualize o estado do formulário sempre que 'files' mudar
  useEffect(() => {
    setValue(field.name, files, { shouldValidate: true });
  }, [files, field.name, setValue]);

  const handleAdd = async (newFileObjs) => {
    startLoading();
    const uploads = await Promise.all(
      newFileObjs.map(async (fileObj) => {
        try {
          const formData = new FormData();
          formData.append("file", fileObj.file);
          const response = await api.http.post("/upload", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          return {
            file: fileObj.file,
            data: response.data,
            preview: URL.createObjectURL(fileObj.file),
          };
        } catch (error) {
          console.error("Upload error:", error);
          return null;
        }
      })
    );
    stopLoading();
    const validFiles = uploads.filter((file) => file !== null);
    setFiles((prevFiles) => [...prevFiles, ...validFiles]);
  };

  const handleDelete = (deletedFile) => {
    const updatedFiles = files.filter(
      (file) => file.data.id !== deletedFile.data.id
    );
    setFiles(updatedFiles);
  };

  const handlePreviewIcon = (fileObject, classes) => (
    <Link
      onClick={() => {
        setSelectedFile(fileObject.data);
        setOpenDlgVis(true);
      }}
    >
      <ImageIcon className={classes.image} />
    </Link>
  );

  return (
    <React.Fragment>
      <DialogoVisualizador
        file={selectedFile}
        open={openDlgVis}
        onClose={() => setOpenDlgVis(false)}
      />
      <div className="dropzone-component">
        <DropzoneAreaBase
          name={field.name}
          filesLimit={15}
          maxFileSize={52428800}
          fileObjects={files}
          dropzoneText={label}
          onAdd={handleAdd}
          onDelete={handleDelete}
          getPreviewIcon={handlePreviewIcon}
          showPreviews={true}
          showPreviewsInDropzone={false}
          showFileNamesInPreview={true}
          showAlerts={["error"]}
          previewText="Documentos"
          dropzoneProps={{ disabled }}
          acceptedFiles={["image/jpeg", "image/png"]}
        />
        {errors[field.name] && (
          <FormHelperText error>{errors[field.name]?.message}</FormHelperText>
        )}
      </div>
    </React.Fragment>
  );
};

export default FileListFieldPreDiagnostico;
