import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
} from "@mui/material";
import { useStoreState } from "easy-peasy";
import './ModalRelResumo.css';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, Tooltip, Legend, CategoryScale, LinearScale } from 'chart.js';
ChartJS.register(BarElement, Tooltip, Legend, CategoryScale, LinearScale); // Registrar elementos necessários

const RelatorioResultadoPreDiagnosticoNaoCapex = ({ lstQuestionario, lstRespostas, cmpcapaSegmento }) => {
  let currProcesso = useStoreState((state) => state.processo.current);
  const [totalSugestao, setTotalSugestao] = useState(0);
  const [listaSugestao, setListaSugestao] = useState();
  const [sumarizaSugestao, setSumarizaSugestao] = useState();
  const [barChartxAxisData, setBarChartxAxisData] = React.useState([]);
  const [barChartseriesData, setBarChartSeriesData] = React.useState([]);

  const [cargas, setCargas] = React.useState(null);

  const [seriesData, setSeriesData] = React.useState([]);

  // Cores para cada barra
  const cores = [
    '#0000CD', '#00ff00', '#F0E68C', '#4169E1',
    '#008000', '#293896', '#6495ED',
    '#62BB46', '#B0E0E6', '#F39325', '#4682B4',
    '#00BFFF', '#FF8C00', '#81D3EB',

  ];

  const data = {
    labels: seriesData.map((item) => item.label), // Rótulos das categorias
    datasets: [
      {
        label: 'Ações por carga alvo',
        data: seriesData.map((item) => item.value), // Valores para cada categoria
        backgroundColor: cores, // Cores diferentes para cada barra
        borderColor: '#000', // Cor da borda das barras
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true, // Iniciar eixo Y do zero
      },
    },
    plugins: {
      legend: {
        display: false, // Opcional: oculta a legenda do gráfico
      },
    },
  };

  const listQuestionsByLoads = [];

  // Função para adicionar novos valores ao grafico de barras
  const addCustoNewData = (xLabel, newValue) => {
    setBarChartxAxisData((prevuDataCusto) => [...prevuDataCusto, xLabel]);
    setBarChartSeriesData((prevxLabelsCusto) => [...prevxLabelsCusto, newValue]);
  };

  const adicionarItem = (item) => {
    setSeriesData((dadosAtuais) => [...dadosAtuais, item]); // Adiciona o novo item ao final da lista
  };

  //função que lista as cargas selecionadas
  const listaCargasSelecionadas = () => {
    const groupsAndAnswers = [];
    lstQuestionario.steps.forEach((step) => {
      if (step.name === "existing-loads") {
        step.questions?.forEach((question) => {
          const quantidaderespostas = countQuestionsExistingLoads(question.existingLoadsNames);
          if (
            question.name !== "existing-loads_10" &&
            lstRespostas.prediagnostico[question.name] === true &&
            quantidaderespostas > 0
          ) {
            setTotalSugestao((prevTotal) => prevTotal + quantidaderespostas);
            const novoItem = { value: quantidaderespostas, label: question.description };
            adicionarItem(novoItem);
            groupsAndAnswers.push({
              Name: question.existingLoadsNames,
              Description: question.description,
              QtdAcoes: quantidaderespostas,
              Peso: question.weight
            });
          }
        });
      }
    });
    setListaSugestao(listQuestionsByLoads.sort((a, b) => b.weight - a.weight));
    return groupsAndAnswers;
  };

  //função que conta a quantidade de respostas que estão disponiveis 
  const countQuestionsExistingLoads = (groupName) => {
    const groupsAndAnswers = [];
    let contador = 0;
    lstQuestionario.steps.forEach((step) => {
      if (step.name === "existing-loads") {
        step.groups?.forEach((group) => {
          if (group.name === groupName) {
            const groupDescription = group.description;
            group.questions?.forEach((question) => {
              if (
                question.capex === false
                && question.validation.type !== "FILE"
                && question.energyEficiencySugestion !== ""
                && ((question.reportSugest === true ? 1 : 0) === parseInt(lstRespostas.prediagnostico[question.name]))
              ) {
                const exists = groupsAndAnswers.some(
                  (item) => item.energyEficiencySugestion === question.energyEficiencySugestion
                );
                if (!exists && lstRespostas.prediagnostico[question.name] !== undefined) {
                  groupsAndAnswers.push({
                    name: question.name,
                    description: question.description,
                    observations: question.observations,
                    energyEficiencySugestion: question.energyEficiencySugestion,
                  });
                  contador += 1;

                  listQuestionsByLoads.push({
                    name: question.name,
                    description: groupDescription,
                    observations: question.energyEficiencySugestion,
                    economyPercentualFrom: question.economyPercentualFrom,
                    weight: question.weight
                  });

                }
              }
            });
          }
        });
      }
    });
    return contador;
  };

  useEffect(() => {
    setCargas(listaCargasSelecionadas());
  }, []);

  return (
    <>
      <Box className='title' sx={{ pageBreakBefore: 'always' }}>
        <Typography variant="h4" gutterBottom>
          Resultados do pré-diagnóstico
        </Typography>
      </Box>
      <Box className='title-section'>
        <Typography variant="h4" gutterBottom>
          Resultados do pré-diagnóstico SEM CAPEX
        </Typography>
      </Box>
      <Box className='item-response-justify grid-container'>
        <Typography variant="subtitle1" gutterBottom>
          O pré-diagnóstico de eficiência energética SEM CAPEX desenvolvido para a empresa <b>{currProcesso.razaoSocial}</b> do ramo de <b>{cmpcapaSegmento}</b> localizada no endereço <b>{currProcesso.rua} {currProcesso.numero}</b> obteve um total de <b>{totalSugestao}</b> sugestões de ações de eficiência energética com base nas respostas do pré-diagnóstico. As ações identificadas são apresentadas na tabela e nos gráficos abaixo. As ações estão divididas por pesos, em que as ações indicadas com peso 3, possuem maior prioridade, devido a elevada economia de energia e redução de emissões que podem resultar.
        </Typography>
      </Box>
      <Grid container spacing={0} className='grid-container'>
        <Grid item xs={4} className='item-response-center'>
          <Typography variant="subtitle2" gutterBottom>Uso Final</Typography>
        </Grid>
        <Grid item xs={8} className='item-response-center'>
          <Typography variant="subtitle2" gutterBottom>Quantidade de ações sugeridas para cada carga</Typography>
        </Grid>
        {(cargas && cargas.length > 0) ?
          cargas.map((item, index) => (
            <>
              <Grid item xs={4} className='item-response-center'>
                <Typography variant="subtitle2" gutterBottom>{item.Description}</Typography>
              </Grid>
              <Grid item xs={8} className='item-response-center'>
                <Typography variant="subtitle2" gutterBottom>{item.QtdAcoes}</Typography>
              </Grid>
            </>
          )) : null}
        <Grid item xs={4} className='item-response-center-color'>
          <Typography variant="subtitle2" gutterBottom>Total</Typography>
        </Grid>
        <Grid item xs={8} className='item-response-center-color'>
          <Typography variant="subtitle2" gutterBottom>{totalSugestao}</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={0} className='grid-space-container' sx={{ pageBreakBefore: 'always' }}>
        <Box sx={{ width: '80%', marginTop: '20px' }}>
          <Typography variant="h6" gutterBottom>
            Quantidade de ações por carga alvo
          </Typography>
        </Box>
        <Bar data={data} options={options} className='chart-container' /> {/* Renderizar gráfico de barras */}

      </Grid>

      <Grid container spacing={0} className='title-capex-modal'>
        <Grid item xs={3} className='item-response'>
          <Typography variant="subtitle1" gutterBottom>Carga</Typography>
        </Grid>
        <Grid item xs={6} className='item-response'>
          <Typography variant="subtitle1" gutterBottom>Sugestões/possibilidades de ações de EE</Typography>
        </Grid>
        <Grid item xs={2} className='item-response'>
          <Typography variant="subtitle1" gutterBottom>Percentual de economia típico(%)(*)</Typography>
        </Grid>
        <Grid item xs={1} className='item-response'>
          <Typography variant="subtitle1" gutterBottom>Peso(**)</Typography>
        </Grid>
      </Grid>
      <Grid container spacing={0} className='grid-container'>
        {(listaSugestao && listaSugestao.length > 0) ?
          listaSugestao.map((item, index) => (
            <>
              <Grid item xs={3} className='item-response'>
                <Typography variant="subtitle2" gutterBottom>{item.description}</Typography>
              </Grid>
              <Grid item xs={6} className='item-response'>
                <Typography variant="subtitle2" gutterBottom>{item.observations}</Typography>
              </Grid>
              <Grid item xs={2} className='item-response'>
                <Typography variant="subtitle2" gutterBottom>{item.economyPercentualFrom}</Typography>
              </Grid>
              <Grid item xs={1} className='item-response'>
                <Typography variant="subtitle2" gutterBottom>{item.weight}</Typography>
              </Grid>
            </>
          ))
          : null}
      </Grid>
      <Box className='item-response-justify grid-container'>
        <Typography variant="subtitle2" gutterBottom>
          (*)  Os percentuais de economia são indicativos que foram estimados com base em outros projetos de EE, e que, apesar de serem uma aproximação inicial aceitável, não devem ser tomados como garantia de economia alcançada. Os percentuais encontrados na realidade podem ser superiores ou inferiores, em função das particularidades de cada sistema que serão estudados detalhadamente no diagnóstico energético.
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          Os percentuais de economia de duas ou mais MEEs NÃO podem ser somados, nem quando se referirem a mesma carga-alvo visto que esse percentual não leva em consideração a carga total da indústria e algumas medidas são excludentes, ou seja, caso decida implementar uma delas, outras poderão não ser mais aplicáveis. Além disso, o percentual de economia de uma MEE pode impactar no percentual de outra MEE.
        </Typography>
      </Box>
      <Box className='item-response-justify grid-container'>
        <Typography variant="subtitle2" gutterBottom>
          (**) - ⁠Ações com peso 3 envolvem sistemas térmicos e elevado CAPEX
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          - Ações com peso 2 envolvem ações de CAPEX em sistemas elétricos e/ou ações de OPEX para sistemas térmicos
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          - Ações com peso 1 envolvem ações de OPEX para os sistemas
        </Typography>
      </Box>

    </>
  );
};

export default RelatorioResultadoPreDiagnosticoNaoCapex;
