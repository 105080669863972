import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import api from "../../services/api";
import './ModalRelResumo.css';

const RelatorioResultadoFotos = ({ lstQuestionario, lstRespostas, dataEmissao, auditor }) => {
  const [cargas, setCargas] = useState(null);
  const [consultor, setConsultor] = useState(null);

  useEffect(() => {
    const fetchCargas = async () => {
      const selectedCargas = await listaCargasSelecionadas();
      const cargasWithQuestions = await Promise.all(selectedCargas.map(async (carga) => {
        const questions = await retornaQuestionsExistingLoads(carga.Name);
        return { ...carga, questions };
      }));
      setCargas(cargasWithQuestions);
    };

    fetchCargas();
  }, [lstQuestionario, lstRespostas]);

  const geturlaws = async (id) => {
    const dados = (await api.http.get(`/prediagnosticos/${id}/getsignedaws`)).data;
    return dados.fileRef;
  };

  const listaCargasSelecionadas = async () => {
    const groupsAndAnswers = [];
    for (const step of lstQuestionario.steps) {
      if (step.name === "existing-loads") {
        for (const question of step.questions || []) {
          const arquivos = await retornaQuestionsExistingLoads(question.existingLoadsNames);
          if (
            question.name !== "existing-loads_10" &&
            lstRespostas.prediagnostico[question.name] === true &&
            arquivos.length > 0
          ) {
            groupsAndAnswers.push({
              Name: question.existingLoadsNames,
              Description: question.description,
              Arquivos: arquivos,
            });
          }
        }
      }
    }
    return groupsAndAnswers;
  };

  const retornaQuestionsExistingLoads = async (groupName) => {
    const listQuestionsByLoads = [];

    for (const step of lstQuestionario.steps) {
      if (step.name === "existing-loads") {
        for (const group of step.groups || []) {
          if (group.name === groupName) {
            for (const question of group.questions || []) {
              if (question.validation.type === "FILE" && lstRespostas.prediagnostico[question.name]?.length > 0) {
                const urljson = lstRespostas.prediagnostico[question.name];
                const urlsPromises = urljson.map(element => geturlaws(element.data.id));
                const urls = await Promise.all(urlsPromises);

                urls.forEach((url) => {
                  listQuestionsByLoads.push({
                    name: question.name,
                    description: question.description,
                    fotosUrl: url,
                  });
                });
              }
            }
          }
        }
      }
    }

    return listQuestionsByLoads;
  };

  // if (!cargas) {
  //   return <div>Carregando...</div>;
  // }

  return (
    <div style={{
      pageBreakBefore: 'always',
      pageBreakInside: 'avoid'
    }}>
      {/* <Box className='title-comentarios-adicionais grid-container'>
        <Typography variant="h4" gutterBottom>
          Comentários adicionais do consultor
        </Typography>
      </Box> */}

      {cargas && (
        <>
          <Box className='title grid-container '>
            <Typography variant="h4" gutterBottom>
              Anexo - Fotos da instalação e dos equipamentos
            </Typography>
          </Box>
          {cargas.length > 0 && cargas.map((item, index) => (
            <React.Fragment key={index}>
              <Box className='title-section'>
                <Typography variant="h4" gutterBottom>
                  {item.Description}
                </Typography>
              </Box>
              {item.questions && item.questions.map((question, idx) => (
                <div key={idx} className='fixed-height-div'>
                  <img className='imgRelatorio' src={question.fotosUrl} />
                </div>
              ))}
            </React.Fragment>
          ))}
        </>
      )}

      <Grid container spacing={1} className="grid-container-modal"
        sx={{
          pageBreakBefore: 'always',
          justifyContent: 'center',
          alignItems: 'center'
        }}>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Box className="framed-box" >
            <Typography variant="h3" gutterBottom>
              Neste documento, o especialista {auditor} atesta que foram consideradas TODAS as cargas existentes na empresa, conforme apresentado acima, de acordo com o que é exigido pelo Programa para maximizar os resultados alcançáveis:
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sx={{
          textAlign: 'center',
          marginTop: '45%',
          marginBottom: '100%'
        }}>
          <Box sx={{
            borderTop: '1px solid black',
            paddingTop: '16px',
            marginTop: '20px'
          }}>
            <Typography variant="h4" gutterBottom>
              {auditor} - {dataEmissao}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default RelatorioResultadoFotos;