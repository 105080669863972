import React, { useEffect, useState } from "react";
import api from "../../services/api";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import PizZipUtils from "pizzip/utils/index.js";
import { saveAs } from "file-saver";
import { Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useStoreActions } from "easy-peasy";
import { format } from "date-fns";
import * as Excel from "exceljs";

function loadFile(url, callback) {
  PizZipUtils.getBinaryContent(url, callback);
}

function formatarNumeroInteiro(numero) {
  // Formata o número para incluir separador de milhares
  return numero.toLocaleString('pt-BR', { minimumFractionDigits: 0 });
}

function createFormattedCell(cell, text) {
  cell.value = text;
  cell.border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
}

function createFormattedCellNegativo(cell, text) {
  cell.value = text;
  cell.border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
  cell.fill = {
    type: 'pattern',
    pattern: 'solid',
    fgColor: { argb: 'FFFF0000' } // Cor vermelha como exemplo, você pode alterar para a cor desejada
  };
}


function generateSheetRow(worksheet, rowData, index) {
  createFormattedCell(worksheet.getRow(index).getCell(1), rowData.razao_social);
  createFormattedCell(
    worksheet.getRow(index).getCell(2),
    rowData.cnpj !== null
      ? rowData.cnpj
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1")
      : ""
  );
  createFormattedCell(
    worksheet.getRow(index).getCell(3),
    new Date(rowData.created_at)
  );
  createFormattedCell(worksheet.getRow(index).getCell(4), rowData.estado);
  createFormattedCell(worksheet.getRow(index).getCell(5), rowData.cidade);
  createFormattedCell(worksheet.getRow(index).getCell(6), rowData.estagio);
  createFormattedCell(worksheet.getRow(index).getCell(7), rowData.segmento);
  createFormattedCell(worksheet.getRow(index).getCell(8), rowData.numero_funcionarios);
}

function generateSheetRowFin(worksheet, rowData, index) {
  createFormattedCell(worksheet.getRow(index + 1).getCell(1), rowData.razao_social);
  createFormattedCell(
    worksheet.getRow(index + 1).getCell(2),
    rowData.cnpj !== null
      ? rowData.cnpj
        .replace(/\D/g, "")
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d)/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1")
      : ""
  );
  createFormattedCell(
    worksheet.getRow(index + 1).getCell(3),
    new Date(rowData.created_at)
  );
  createFormattedCell(worksheet.getRow(index + 1).getCell(4), rowData.estado);
  createFormattedCell(worksheet.getRow(index + 1).getCell(5), rowData.cidade);
  createFormattedCell(worksheet.getRow(index + 1).getCell(6), rowData.estagio);
  createFormattedCell(worksheet.getRow(index + 1).getCell(7), rowData.segmento);
  createFormattedCell(worksheet.getRow(index + 1).getCell(9), formatarNumeroInteiro(rowData.esperado_kwh));
  createFormattedCell(worksheet.getRow(index + 1).getCell(8), formatarNumeroInteiro(rowData.esperado_co2));
  if (rowData.esperado_kwh > rowData.verificado_kwh) {
    createFormattedCellNegativo(worksheet.getRow(index + 1).getCell(11), formatarNumeroInteiro(rowData.verificado_kwh));
  } else {
    createFormattedCell(worksheet.getRow(index + 1).getCell(11), formatarNumeroInteiro(rowData.verificado_kwh));
  }
  if (rowData.esperado_co2 > rowData.verificado_co2) {
    createFormattedCellNegativo(worksheet.getRow(index + 1).getCell(10), formatarNumeroInteiro(rowData.verificado_co2));
  } else {
    createFormattedCell(worksheet.getRow(index + 1).getCell(10), formatarNumeroInteiro(rowData.verificado_co2));
  }
}

function generateSheetRowATS(worksheet, rowData, index) {
  createFormattedCell(worksheet.getRow(index + 1).getCell(1), rowData.Processo);
  createFormattedCell(worksheet.getRow(index + 1).getCell(2), rowData.Scrum_Master);
  createFormattedCell(worksheet.getRow(index + 1).getCell(3), rowData.Especialista);
  createFormattedCell(worksheet.getRow(index + 1).getCell(4), rowData.Estagio);
  createFormattedCell(worksheet.getRow(index + 1).getCell(5), rowData.updateAT);

}

const RelatorioGeral = () => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

  const generateDocument = async () => {
    startLoading();
    try {
      let dados = (await api.http.get("/relatorios/relatorio-geral")).data;
      if (!dados || dados.length === 0) {
        stopLoading({
          message: "Nenhum registro encontrado.",
          severity: "error",
        });
        return;
      }

      const workbook = new Excel.Workbook();
      workbook.created = new Date();
      workbook.modified = new Date();

      const worksheet = workbook.addWorksheet("principal");

      const headerRow = worksheet.getRow(1);
      headerRow.alignment = { vertical: "middle", horizontal: "center" };
      createFormattedCell(headerRow.getCell(1), "Razão Social");
      worksheet.getColumn("A").width = 85;
      createFormattedCell(headerRow.getCell(2), "CNPJ");
      worksheet.getColumn("B").width = 20;
      worksheet.getColumn("B").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      createFormattedCell(headerRow.getCell(3), "Data de Cadastro");
      worksheet.getColumn("C").dateFmt = "dd/mm/yyyy";
      worksheet.getColumn("C").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getColumn("C").width = 15;

      createFormattedCell(headerRow.getCell(4), "Estado");
      worksheet.getColumn("D").width = 7;
      worksheet.getColumn("D").alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      createFormattedCell(headerRow.getCell(5), "Cidade");
      worksheet.getColumn("E").width = 30;

      createFormattedCell(headerRow.getCell(6), "Estágio");
      worksheet.getColumn("F").width = 45;
      createFormattedCell(headerRow.getCell(7), "Segmento");
      worksheet.getColumn("G").width = 45;

      createFormattedCell(headerRow.getCell(8), "Número de Funcionários");
      worksheet.getColumn("H").width = 25;

      let lastRowIndex = 0;
      for (; lastRowIndex < dados.length; lastRowIndex++) {
        generateSheetRow(worksheet, dados[lastRowIndex], lastRowIndex + 2);
      }

      /*************************************************************/
      /*          aba projetos finalizados       */
      let dadosFin = (await api.http.get("relatorios/relatorio-geral-finalizado")).data;
      const worksheetFin = workbook.addWorksheet("Projetos finalizados");

      worksheetFin.mergeCells("A1:A2");
      worksheetFin.mergeCells("B1:B2");
      worksheetFin.mergeCells("C1:C2");
      worksheetFin.mergeCells("D1:D2");
      worksheetFin.mergeCells("E1:E2");
      worksheetFin.mergeCells("F1:F2");
      worksheetFin.mergeCells("G1:G2");
      worksheetFin.mergeCells("H1:I1");
      // worksheetFin.mergeCells("I1:I3");
      worksheetFin.mergeCells("J1:K1");
      // worksheetFin.mergeCells("K1:K3");

      const headerRowFin = worksheetFin.getRow(1);
      headerRowFin.alignment = { vertical: "middle", horizontal: "center" };
      createFormattedCell(headerRowFin.getCell(1), "Razão Social");
      worksheetFin.getColumn("A").width = 85;

      createFormattedCell(headerRowFin.getCell(2), "CNPJ");
      worksheetFin.getColumn("B").width = 20;
      worksheetFin.getColumn("B").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      createFormattedCell(headerRowFin.getCell(3), "Data de Cadastro");
      worksheetFin.getColumn("C").dateFmt = "dd/mm/yyyy";
      worksheetFin.getColumn("C").alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheetFin.getColumn("C").width = 15;

      createFormattedCell(headerRowFin.getCell(4), "Estado");
      worksheetFin.getColumn("D").width = 7;
      worksheetFin.getColumn("D").alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      createFormattedCell(headerRowFin.getCell(5), "Cidade");
      worksheetFin.getColumn("E").width = 30;

      createFormattedCell(headerRowFin.getCell(6), "Estágio");
      worksheetFin.getColumn("G").width = 45;

      createFormattedCell(headerRowFin.getCell(7), "Segmento");
      worksheetFin.getColumn("F").width = 45;

      createFormattedCell(headerRowFin.getCell(8), "Resultado esperado");
      worksheetFin.getColumn("H").width = 60;

      createFormattedCell(headerRowFin.getCell(10), "Resultado verificado");
      worksheetFin.getColumn("J").width = 60;


      createFormattedCell(worksheetFin.getRow(2).getCell(8), "T de CO2 cumulativo-vida útil");
      worksheetFin.getColumn("H").width = 30;


      createFormattedCell(worksheetFin.getRow(2).getCell(9), "KWh cumulativo-vida útil");
      worksheetFin.getColumn("I").width = 30;

      createFormattedCell(worksheetFin.getRow(2).getCell(10), "T de CO2 cumulativo-vida útil");
      worksheetFin.getColumn("J").width = 30;

      createFormattedCell(worksheetFin.getRow(2).getCell(11), "KWh cumulativo-vida útil");
      worksheetFin.getColumn("K").width = 30;

      // // Adicionar um grupo para os campos "Esperado Kw/h" e "Esperado Co2"
      // worksheetFin.addRowGroup(1, 1, 1, 2, { outlineLevel: 1 });

      // // Adicionar um grupo para os campos "Verificado Kw/h" e "Verificado Co2"
      // worksheetFin.addRowGroup(1, 1, 1, 2, { outlineLevel: 1 });  

      lastRowIndex = 0;

      for (; lastRowIndex < dadosFin.length; lastRowIndex++) {
        generateSheetRowFin(worksheetFin, dadosFin[lastRowIndex], lastRowIndex + 2);
      }
      /*************************************************************/

      /*************************************************************/
      /*          aba projetos atrasados       */
      let dadosAts = (await api.http.get("relatorios/relatorio-geral-atrasado")).data;

      if (dadosAts) {
        const worksheet = workbook.addWorksheet("Projetos atrasados");
        const headerRow = worksheet.getRow(1);
        // headerRow.alignment = { vertical: "middle", horizontal: "center" };
        // createFormattedCell(headerRow.getCell(1), "Processo");
        // worksheet.getColumn("A").width = 85;

        // createFormattedCell(headerRow.getCell(2), "Especialista Lider");
        // worksheet.getColumn("B").width = 20;

        // createFormattedCell(headerRow.getCell(3), "Especialista");
        // worksheet.getColumn("C").width = 85;

        // createFormattedCell(headerRow.getCell(4), "Estágio");
        // worksheet.getColumn("D").width = 40;

        // createFormattedCell(headerRow.getCell(5), "Data última atualização");
        // worksheet.getColumn("E").width = 20;

        // lastRowIndex = 0;

        // for (; lastRowIndex < dadosAts.length; lastRowIndex++) {
        //   generateSheetRowATS(worksheet, dadosAts[lastRowIndex], lastRowIndex + 1);
        // }

        headerRow.alignment = { vertical: "middle", horizontal: "center" };
        addHeaderCells(headerRow, worksheet);

        populateWorksheetWithData(worksheet, dadosAts);
        addLegend(worksheet);

      }

      /*************************************************************/

      const buffer = await workbook.xlsx.writeBuffer();
      saveAs(
        new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        "export-relatorio-geral-" + new Date().toISOString() + ".xlsx"
      );
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  function addHeaderCells(headerRow, worksheet) {
    const headers = [
      { title: "Processo", width: 85 },
      { title: "Especialista Lider", width: 20 },
      { title: "Especialista", width: 85 },
      { title: "Estágio", width: 40 },
      { title: "Data última atualização", width: 20 },
    ];

    headers.forEach((header, index) => {
      const cell = headerRow.getCell(index + 1);
      createFormattedCell(cell, header.title);
      worksheet.getColumn(String.fromCharCode(65 + index)).width = header.width; // A, B, C, ...
    });
  }

  function populateWorksheetWithData(worksheet, data) {
    data.forEach((item, index) => {
      generateSheetRowATS(worksheet, item, index + 2);
    });
  }

  // function addLegend(worksheet) {
  //   const startRowIndex = worksheet.lastRow.number + 4;

  //   const legendData = [
  //     { stage: "Cadastro de elegíveis", period: "1 semana" },
  //     { stage: "Pré-diagnóstico", period: "1 mês" },
  //     { stage: "Diagnóstico", period: "4 meses" },
  //     { stage: "Financiamento", period: "1 mês" },
  //     { stage: "Cadastro de projeto", period: "2 semanas" },
  //     { stage: "Implementação", period: "6 meses" },
  //   ];

  //   const legendTitleRow = worksheet.getRow(startRowIndex);
  //   createFormattedCell(legendTitleRow.getCell(1), "Etapas");
  //   createFormattedCell(legendTitleRow.getCell(2), "Período");

  //   legendData.forEach((legendItem, index) => {
  //     const rowIndex = startRowIndex + index + 1;
  //     const row = worksheet.getRow(rowIndex);
  //     createFormattedCell(row.getCell(1), legendItem.stage);
  //     createFormattedCell(row.getCell(2), legendItem.period);
  //   });
  // }

  function addLegend(worksheet) {
    // Defina o índice da linha para o cabeçalho adicional
    const headerRowIndex = worksheet.lastRow.number + 3;

    // Crie uma nova linha para o cabeçalho adicional
    const headerRow = worksheet.getRow(headerRowIndex);
    createFormattedCell(headerRow.getCell(1), "Regras para classificação de projetos em atraso por estágio");

    // Defina o índice da linha inicial para a legenda
    const startRowIndex = worksheet.lastRow.number + 2;

    const legendData = [
      { stage: "Cadastro de elegíveis", period: "1 semana" },
      { stage: "Pré-diagnóstico", period: "1 mês" },
      { stage: "Diagnóstico", period: "4 meses" },
      { stage: "Financiamento", period: "1 mês" },
      { stage: "Cadastro de projeto", period: "2 semanas" },
      { stage: "Implementação", period: "6 meses" },
    ];

    const legendTitleRow = worksheet.getRow(startRowIndex);
    createFormattedCell(legendTitleRow.getCell(1), "Etapas");
    createFormattedCell(legendTitleRow.getCell(2), "Período");

    legendData.forEach((legendItem, index) => {
      const rowIndex = startRowIndex + index + 1;
      const row = worksheet.getRow(rowIndex);
      createFormattedCell(row.getCell(1), legendItem.stage);
      createFormattedCell(row.getCell(2), legendItem.period);
    });
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={generateDocument}
        sx={{ height: 45 }}
      >
        <FileDownloadIcon /> RELATÓRIO GERAL (XLSX)
      </Button>
    </>
  );
};

export default RelatorioGeral;
