import * as React from "react";
import MaterialTable from "@material-table/core";
import { Box, Paper, Fab } from "@mui/material";
import api from "../../services/api";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useStoreState, useStoreActions } from "easy-peasy";
import { materialTableLocalization } from "../../config";
import { Rule } from "@mui/icons-material";

const LASTQUERY = "last_search_query";
const LASTPAGE = "last_page_query";

const Processos = () => {
  let user = useStoreState((state) => state.auth.user);
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);

  const [columns, setColumns] = React.useState([]);

  const loadProcessos = async (query) => {
    startLoading();
    try {
      let getProcessos = (
        await api.http.get("/processos", {
          params: {
            page: query.page + 1,
            pageSize: query.pageSize,
            search: query.search,
            orderBy: query.orderBy ? query.orderBy.field : undefined,
            orderDirection: query.orderDirection
              ? query.orderDirection
              : undefined,
            userId: user.id,
            roleId: user.roleId,
          },
        })
      ).data;

      sessionStorage.setItem(LASTQUERY, query.search);
      sessionStorage.setItem(LASTPAGE, getProcessos.meta.current_page - 1);

      let estagios = (await api.http.get("/estagios")).data;

      stopLoading();
      return {
        data: await Promise.all(getProcessos.data.map(async (data) => {
          let concatEstagio = "";

          const matchingEstagio = estagios.find(estagio => estagio.id === data.estagio);

          if (matchingEstagio) {
            concatEstagio = matchingEstagio.nome;
          }

          return {
            ...data,
            estagio: concatEstagio,
            porte: (data.numFuncs <= 19) ? "Microempresa" :
              (data.numFuncs <= 99) ? "Pequena Empresa" : "Média Empresa",
          };
        })),
        page: getProcessos.meta.current_page - 1,
        totalCount: getProcessos.meta.total,
      };
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      return {
        data: [],
        page: 0,
        totalCount: 0,
      };
    }
  };

  let navigate = useNavigate();

  const loadColumns = async (mount) => {
    if (mount.isMounted) {
      setColumns([
        {
          field: "id",
          align: "right",
          title: "Cod. Processo",
        },
        {
          field: "razaoSocial",
          align: "left",
          title: "Razão Social",
        },
        {
          field: "porte",
          align: "left",
          title: "Porte",
        },
        {
          field: "estagio",
          align: "left",
          title: "Estágio",
        },
        {
          field: "createdAt",
          align: "center",
          title: "Data do interesse",
          type: "date",
        },
        {
          field: "updatedAt",
          align: "center",
          title: "Atualização",
          type: "date",
        },
      ]);
    }
  };

  React.useEffect(() => {
    let mount = {
      isMounted: true,
    };
    loadColumns(mount);
    return () => {
      mount.isMounted = false;
    };
  }, []);

  const onRowClick = (event, rowData) => {
    navigate("/processo/" + rowData.id);
  };

  const showAddProcesso = () => {
    return [
      "superAdmin",
      "consultorEE",
      "validadorIndSENAI",
      "gestorSENAI",
    ].includes(user.roleId);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        variant="outlined"
        className="center-box"
        sx={{ width: "100%", my: 3 }}
      >
        <MaterialTable
          columns={columns}
          data={loadProcessos}
          title="Processos"
          onRowClick={onRowClick}
          localization={materialTableLocalization}
          options={{
            searchText: sessionStorage.getItem(LASTQUERY)
              ? sessionStorage.getItem(LASTQUERY)
              : "",
            initialPage: sessionStorage.getItem(LASTPAGE)
              ? parseInt(sessionStorage.getItem(LASTPAGE))
              : 0,
          }}
        />
        {showAddProcesso() && (
          <Fab
            color="primary"
            aria-label="novo processo"
            onClick={() => navigate("/processo/")}
          >
            <AddIcon />
          </Fab>
        )}
      </Paper>
    </Box>
  );
};

export default Processos;
