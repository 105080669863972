import React, { useState } from "react";
import api from "../../services/api";
import {
  Paper,
  Grid,
  Box,
  Container,
  FormControl,
  Checkbox,
  Button,
  Stack,
  Card,
  Typography,
  CardContent,
} from "@mui/material";
import { Autocomplete, DateField } from "../../components";
import { useStoreActions } from "easy-peasy";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { pt } from "yup-locale-pt";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { Chart } from "react-chartjs-2";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineController,
  BarController,
  ArcElement,
  PieController,
  CategoryScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "chartjs-adapter-date-fns";
import autocolors from "chartjs-plugin-autocolors";
import zoomPlugin from "chartjs-plugin-zoom";
import MaterialTable from "@material-table/core";
import { listaComoSoubePrograma } from "../../static";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

yup.setLocale(pt);
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const IndicadoresTecnicos = () => {
  let startLoading = useStoreActions((actions) => actions.nav.startLoading);
  let stopLoading = useStoreActions((actions) => actions.nav.stopLoading);
  const [regioes, setRegioes] = React.useState([]);
  const [segmentosIndustriais, setSegmentosIndustriais] = useState([]);
  const [estagios, setEstagios] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [datasets, setDatasets] = React.useState({});

  const formatCurrency = (value) => {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  };

  const integracoes = [{
    id: 'acumulativo',
    label: 'Dados cumulativo vida-útil'
  }, {
    id: 'fracionado',
    label: 'Fracionados em 10 anos'
  }];

  const schema = yup
    .object({
      regiao: yup.array().of(yup.number()).label("Regiões"),
      segmentoIndustrial: yup
        .array()
        .of(yup.number())
        .label("Segmentos industriais"),
      estagio: yup.array().of(yup.string()).label("Estágios"),
      de: yup.date().label("De").nullable(),
      ate: yup.date().label("Até").nullable(),
      porte: yup.array().of(yup.string()).label("Porte"),
      integracao: yup.string()
        .nullable()
        .oneOf(integracoes.map((integracao) => integracao.id))
        .label('Integração')
        .required(),
      categoria: yup.array().of(yup.string()).label("Categorias de Tecnologia"),
    })
    .required();

  const useFormRef = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    defaultValues: {
      serie: "regiao",
      agrupamento: "mes",
      integracao: "acumulativo",
      de: null,
      ate: null,
    },
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting },
  } = useFormRef;

  //load datas
  const deField = watch("de");
  const ateField = watch("ate");
  const estagiosSelecionados = watch("estagio");
  const regioesSelecionadas = watch("regiao");
  const segmentosSelecionados = watch("segmentoIndustrial");
  const integracaoSelecionada = watch("integracao");

  const portes = [
    {
      id: "micro",
      name: "Microempresa",
    },
    {
      id: "pequena",
      name: "Pequena Empresa",
    },
    {
      id: "Média",
      name: "Média Empresa",
    },
  ];

  React.useEffect(() => {
    if (deField && ateField && deField > ateField) {
      setValue("ate", deField);
    }
  }, [deField]);

  const loadSetoresAtuacao = async (mount) => {
    startLoading();
    try {
      let data = (await api.http.get("/setores-atuacao")).data;
      data = [
        {
          id: -1,
          nome: "Todos",
        },
        ...data,
      ];
      if (mount.isMounted) setSegmentosIndustriais(data);
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  React.useEffect(() => {
    if (segmentosSelecionados === null || segmentosSelecionados === undefined)
      return;
    if (
      segmentosSelecionados.find((e) => e === -1) !== undefined &&
      segmentosSelecionados.length !== segmentosIndustriais.length
    ) {
      setValue(
        "segmentoIndustrial",
        segmentosIndustriais.map((e) => e.id)
      );
    } else if (
      segmentosSelecionados.length === segmentosIndustriais.length - 1 &&
      segmentosSelecionados.find((e) => e === -1) === undefined
    ) {
      setValue("regiao", []);
    }
  }, [segmentosSelecionados]);

  const loadRegioes = async (mount) => {
    startLoading();
    try {
      let data = (await api.http.get("/regioes")).data;
      data = [
        {
          id: -1,
          label: "Todas",
        },
        ...data,
      ];
      if (mount.isMounted) setRegioes(data);
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  React.useEffect(() => {
    if (regioesSelecionadas === null || regioesSelecionadas === undefined)
      return;
    if (
      regioesSelecionadas.find((e) => e === -1) !== undefined &&
      regioesSelecionadas.length !== regioes.length
    ) {
      setValue(
        "regiao",
        regioes.map((e) => e.id)
      );
    } else if (
      regioesSelecionadas.length === regioes.length - 1 &&
      regioesSelecionadas.find((e) => e === -1) === undefined
    ) {
      setValue("regiao", []);
    }
  }, [regioesSelecionadas]);

  const loadEstagios = async (mount) => {
    startLoading();

    try {
      let estagios = (await api.http.get("/estagios")).data;
      estagios = [
        {
          id: "todos",
          nome: "Todos",
        },
        ...estagios,
      ];
      if (mount.isMounted) {
        setEstagios(estagios);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
      throw error;
    }
  };

  const loadCategorias = async (mount) => {
    startLoading();
    try {
      let data = (await api.http.get("/categorias")).data;
      if (mount.isMounted) {
        setCategorias(data);
      }
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  React.useEffect(() => {
    let mount = {
      isMounted: true,
    };
    loadSetoresAtuacao(mount);
    loadRegioes(mount);
    loadEstagios(mount);
    loadCategorias(mount);
  }, []);

  React.useEffect(() => {
    if (estagiosSelecionados === null || estagiosSelecionados === undefined)
      return;
    if (
      estagiosSelecionados.find((e) => e === "todos") !== undefined &&
      estagiosSelecionados.length !== estagios.length
    ) {
      setValue(
        "estagio",
        estagios.map((e) => e.id)
      );
    } else if (
      estagiosSelecionados.length === estagios.length - 1 &&
      estagiosSelecionados.find((e) => e === "todos") === undefined
    ) {
      setValue("estagio", []);
    }
  }, [estagiosSelecionados]);

  const submit = async (data) => {
    startLoading();
    try {
      // setChartTitle(indicadores.find((ind) => ind.id == indicador).label);
      let newDatasets = (
        await api.http.post("/indicadores-tecnicos/datasets", {
          ...data,
        })
      ).data;

      setDatasets(newDatasets);
      if (newDatasets.length == 0) {
        stopLoading({
          message: "Nenhum registro encontrado.",
          severity: "error",
        });
      } else {
        stopLoading();
      }
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  const exportar = async (data) => {
    startLoading();

    try {
      let elem = chartRef.current;

      let canvas = await html2canvas(elem, {
        backgroundColor: "#fff",
        allowTaint: false,
        scale: 1.5,
      });
      let image = canvas.toDataURL("image/png");
      const pdf = new jsPDF("portrait", "px", "a4");

      pdf.addImage(
        image,
        "PNG",
        pdf.internal.pageSize.getWidth() / 2.0 - 105,
        0,
        210,
        pdf.internal.pageSize.getHeight()
      );

      pdf.save(`indicadores-tecnicos-${new Date().toISOString()}.pdf`);
      stopLoading();
    } catch (error) {
      stopLoading({
        message: error.toString(),
        severity: "error",
      });
    }
  };

  ChartJS.register(
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    ArcElement,
    PieController,
    LineController,
    BarController,
    Title,
    Tooltip,
    Legend,
    autocolors,
    zoomPlugin,
    CategoryScale,
    ChartDataLabels
  );

  ChartJS.defaults.set("plugins.datalabels", {
    display: false,
  });

  const chartRef = React.useRef(null);

  function headerSerie(str) {
    if (str == "regiao") {
      return "Regiões";
    } else if (str == "segmento industrial") {
      return "Segmento Industrial";
    } else {
      return null;
    }
  }

  return (
    <Container maxWidth="false">
      <Paper
        variant="outlined"
        className="center-box"
        sx={{
          textAlign: "center",
          px: 4,
          pt: 2,
          pb: 3,
          m: 5,
        }}
      >
        <Box
          component="form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(submit)}
        >
          <Stack direction="row" spacing={2} justifyContent="center"></Stack>
          <Grid container spacing={2} sx={{ mt: 2, mb: 4 }}>
            <Grid item md={4}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  field="regiao"
                  useFormRef={useFormRef}
                  options={regioes}
                  getOptionLabel={(regiao) => regiao.label}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {props.key}
                    </li>
                  )}
                  schema={schema}
                  limitTags={3}
                />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  field="segmentoIndustrial"
                  useFormRef={useFormRef}
                  options={segmentosIndustriais}
                  getOptionLabel={(segmentoInd) => segmentoInd.nome}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {props.key}
                    </li>
                  )}
                  schema={schema}
                  limitTags={3}
                />
              </FormControl>
            </Grid>
            <Grid item md={4}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  field="estagio"
                  useFormRef={useFormRef}
                  options={estagios}
                  getOptionLabel={(estagio) => estagio.nome}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {props.key}
                    </li>
                  )}
                  schema={schema}
                  limitTags={3}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            marginBottom={4}
          >
            <FormControl fullWidth>
              <Autocomplete
                multiple
                disableCloseOnSelect
                field="porte"
                useFormRef={useFormRef}
                options={portes}
                getOptionLabel={(porte) => porte.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {props.key}
                  </li>
                )}
                schema={schema}
                limitTags={3}
              />
            </FormControl>
            <FormControl fullWidth>
              <Autocomplete
                multiple
                disableCloseOnSelect
                field="categoria"
                useFormRef={useFormRef}
                options={categorias}
                getOptionLabel={(categoria) => categoria.nome}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {props.key}
                  </li>
                )}
                schema={schema}
                limitTags={3}
              />
            </FormControl>
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="center">
            <FormControl sx={{ minWidth: 180 }} >
              <Autocomplete
                field="integracao"
                useFormRef={useFormRef}
                options={integracoes}
                schema={schema} />
            </FormControl>
            <FormControl>
              <DateField
                field="de"
                inputFormat={"dd/MM/yyyy"}
                maxDate={new Date()}
                useFormRef={useFormRef}
                schema={schema}
              />
            </FormControl>
            <FormControl>
              <DateField
                field="ate"
                inputFormat={"dd/MM/yyyy"}
                maxDate={new Date()}
                useFormRef={useFormRef}
                schema={schema}
              />
            </FormControl>
            <Button type="submit" variant="contained" disabled={isSubmitting}>
              Plotar
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit(exportar)}
              disabled={Object.keys(datasets).length === 0}
            >
              exportar
            </Button>
          </Stack>
        </Box>
      </Paper>
      <div ref={chartRef}>
        {Object.keys(datasets).length !== 0 && (
          <Paper
            variant="outlined"
            className="center-box"
            sx={{
              textAlign: "center",
              px: 4,
              pt: 2,
              pb: 3,
              m: 5,
            }}
          >
            <Grid container justifyContent={"center"}>
              <Card
                sx={{ width: "100%", marginBottom: "12px" }}
                variant="outlined"
              >
                <CardContent>
                  <Typography
                    sx={{ fontSize: 28, fontWeight: 700 }}
                    color="#62bb46"
                  >
                    Total de Custo do Investimento em EE
                  </Typography>

                  <Chart
                    type={"bar"}
                    options={{
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        title: {
                          display: false,
                          text: "Total de Custo do Investimento em EE",
                        },
                        datalabels: {
                          anchor: 'end',
                          align: 'end',
                          display: 'auto',
                          formatter: (value) => formatCurrency(value),
                        }
                      },
                    }}
                    data={{
                      labels: [
                        `Total Previsto (${formatCurrency(datasets.total_investido)})`,
                        `Total Realizado (${formatCurrency(datasets.total_realizado)})`],
                      datasets: [
                        {
                          data: [
                            datasets.total_investido
                            ,
                            datasets.total_realizado
                          ], // Valores ajustados
                          backgroundColor: ["#43A047", "#1A237E"], // Cores para cada barra
                          hoverOffset: 4,
                        },
                      ],
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid container justifyContent={"space-around"}>
              <Grid item lg={5}>
                <Chart
                  type={"pie"}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Distribuição Funcionários (Ex-Ante)",
                      },
                      datalabels: {
                        display: true,
                        formatter: function (value, context) {
                          return (
                            value +
                            " (" +
                            Intl.NumberFormat("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(
                              (value /
                                (datasets.funcs.fem + datasets.funcs.masc)) *
                              100.0
                            ) +
                            "%)"
                          );
                        },
                        color: "#fff",
                        font: {
                          family: "Roboto",
                          size: 14,
                          weight: 400,
                        },
                        labels: {
                          value: "#fff",
                        },
                      },
                    },
                  }}
                  data={{
                    labels: ["Feminino", "Masculino"],
                    datasets: [
                      {
                        label: "Distribuição Funcionários",
                        data: [datasets.funcs.fem, datasets.funcs.masc],
                        backgroundColor: [
                          "rgb(255, 99, 132)",
                          "rgb(54, 162, 235)",
                        ],
                        hoverOffset: 4,
                      },
                    ],
                  }}
                />
              </Grid>
              <Grid item lg={5}>
                <Chart
                  type={"pie"}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Distribuição Funcionários (Ex-Post)",
                      },
                      datalabels: {
                        display: true,
                        formatter: function (value, context) {
                          return (
                            value +
                            " (" +
                            Intl.NumberFormat("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(
                              (value /
                                (datasets.funcs.fem_mv +
                                  datasets.funcs.masc_mv)) *
                              100.0
                            ) +
                            "%)"
                          );
                        },
                        color: "#fff",
                        font: {
                          family: "Roboto",
                          size: 14,
                          weight: 400,
                        },
                        labels: {
                          value: "#fff",
                        },
                      },
                    },
                  }}
                  data={{
                    labels: ["Feminino", "Masculino"],
                    datasets: [
                      {
                        label: "Distribuição Funcionários M&V",
                        data: [datasets.funcs.fem_mv, datasets.funcs.masc_mv],
                        backgroundColor: [
                          "rgb(255, 99, 132)",
                          "rgb(54, 162, 235)",
                        ],
                        hoverOffset: 4,
                      },
                    ],
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-around"} mt={4}>
              <Grid item lg={5}>
                <Chart
                  type={"pie"}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Distribuição Portes",
                      },
                      datalabels: {
                        display: true,
                        formatter: function (value, context) {
                          return (
                            value +
                            " (" +
                            Intl.NumberFormat("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(
                              (value /
                                JSON.parse(datasets.porte)
                                  .map((a) => a[1])
                                  .reduce((acc, curr) => acc + curr)) *
                              100.0
                            ) +
                            "%)"
                          );
                        },
                        color: "#fff",
                        font: {
                          family: "Roboto",
                          size: 14,
                          weight: 400,
                        },
                        labels: {
                          value: "#fff",
                        },
                        datalabels: {
                          display: true,
                          formatter: function (value, context) {
                            return (
                              value +
                              " (" +
                              Intl.NumberFormat("pt-BR", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(
                                (value /
                                  JSON.parse(datasets.categorias)
                                    .map((a) => a[1])
                                    .reduce((acc, curr) => acc + curr)) *
                                100.0
                              ) +
                              "%)"
                            );
                          },
                          color: "#fff",
                          font: {
                            family: "Roboto",
                            size: 14,
                            weight: 400,
                          },
                          labels: {
                            value: "#fff",
                          },
                        },
                      },
                    },
                  }}
                  data={{
                    labels: ["Micro", "Pequena", "Média"],
                    datasets: [
                      {
                        label: "Distribuição Portes",
                        data: JSON.parse(datasets.porte).map(
                          (porte) => porte[1]
                        ),
                        backgroundColor: ["#D50000", "#F57F17", "#1A237E"],
                        hoverOffset: 4,
                      },
                    ],
                  }}
                />
              </Grid>
              <Grid item lg={5}>
                <Chart
                  type={"pie"}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Distribuição Categorias Tecnológicas",
                      },
                      datalabels: {
                        display: true,
                        formatter: function (value, context) {
                          return (
                            value +
                            " (" +
                            Intl.NumberFormat("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }).format(
                              (value /
                                JSON.parse(datasets.categorias)
                                  .map((a) => a[1])
                                  .reduce((acc, curr) => acc + curr)) *
                              100.0
                            ) +
                            "%)"
                          );
                        },
                        color: "#fff",
                        font: {
                          family: "Roboto",
                          size: 14,
                          weight: 400,
                        },
                        labels: {
                          value: "#fff",
                        },
                      },
                    },
                  }}
                  data={{
                    labels: categorias
                      .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
                      .map((categ) => categ.id + " : " + categ.nome),
                    datasets: [
                      {
                        label: "Distribuição Categorias Tecnológicas",
                        data: JSON.parse(datasets.categorias)
                          .sort((a, b) =>
                            a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0
                          )
                          .map((categoria) => categoria[1]),
                        backgroundColor: ["#7B1FA2", "#E65100", "#C2185B"],
                        hoverOffset: 4,
                      },
                    ],
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} mt={4}>
              <Grid item lg={12}>
                <Chart
                  type={"bar"}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      title: {
                        display: true,
                        text: "Distribuição Setores Industriais",
                      },
                      datalabels: {
                        anchor: 'end',
                        align: 'end',
                        display: 'auto',
                      }
                    },
                  }}
                  data={{
                    labels: segmentosIndustriais
                      .filter((seg) => seg.id !== -1)
                      .sort((a, b) => a.id - b.id)
                      .map((seg) => seg.nome),
                    datasets: [
                      {
                        data: JSON.parse(datasets.segmentos)
                          .sort((a, b) => a[0] - b[0])
                          .map((segmento) => segmento[1]),
                        backgroundColor: [
                          "#D32F2F",
                          "#7B1FA2",
                          "#E65100",
                          "#C2185B",
                          "#512DA8",
                          "#388E3C",
                          "#303F9F",
                          "#1976D2",
                          "#5D4037",
                          "#0097A7",
                          "#00796B",
                        ],
                        hoverOffset: 4,
                      },
                    ],
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} mt={4}>
              <Grid item lg={6}>
                <Chart
                  type={"bar"}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Economina EE (GWh cumulativo-vida útil)",
                      },
                      tooltip: {
                        enabled: true,
                        callbacks: {
                          label: function (context) {
                            let label = context.label || "";
                            if (label) {
                              label += ` ${Intl.NumberFormat("pt-BR", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(context.parsed.y)} (${Intl.NumberFormat(
                                "pt-BR",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              ).format((context.parsed.y / 7_267) * 100.0)}%)`;
                            }
                            return label;
                          },
                        },
                      },
                      datalabels: {
                        anchor: 'end',
                        align: 'end',
                        display: 'auto',
                      }
                    },
                  }}
                  data={{
                    labels: ["Ex-Ante", "Ex-Post"],
                    datasets: [
                      {
                        label: "Dados",
                        data: [
                          datasets.econ_energia / 1_000_000.0,
                          datasets.econ_energia_mv / 1_000_000.0,
                        ],
                        backgroundColor: ["#43A047"],
                        hoverOffset: 4,
                      },
                      {
                        label: "Meta",
                        data: [7_267, 7_267],
                        backgroundColor: ["#1A237E"],
                        hoverOffset: 4,
                      },
                    ],
                  }}
                />
              </Grid>
              <Grid item lg={6}>
                <Chart
                  type={"bar"}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        position: "top",
                      },
                      title: {
                        display: true,
                        text: "Redução de emissões de GEE (T de CO2 cumulativo-vida útil)",
                      },
                      tooltip: {
                        enabled: true,
                        callbacks: {
                          label: function (context) {
                            let label = context.label || "";
                            if (label) {
                              label += ` ${Intl.NumberFormat("pt-BR", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }).format(context.parsed.y)} (${Intl.NumberFormat(
                                "pt-BR",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              ).format(
                                (context.parsed.y / 1_100_000) * 100.0
                              )}%)`;
                            }
                            return label;
                          },
                        },
                      },
                      datalabels: {
                        anchor: 'end',
                        align: 'end',
                        display: 'auto',
                      }
                    },
                  }}
                  data={{
                    labels: ["Ex-Ante", "Ex-Post"],
                    datasets: [
                      {
                        label: "Dados",
                        data: [datasets.econ_co2, datasets.econ_co2_mv],
                        backgroundColor: ["#43A047"],
                        hoverOffset: 4,
                      },
                      {
                        label: "Meta",
                        data: [1_100_000, 1_100_000],
                        backgroundColor: ["#1A237E"],
                        hoverOffset: 4,
                      },
                    ],
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} mt={4}>
              <Grid
                lg={12}
                display={"flex"}
                flexDirection={"column"}
                justifyItems={"center"}
                justifyContent={"center"}
              >
                <Chart
                  type={"bar"}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      title: {
                        display: true,
                        text: "Distribuição Estágios",
                      },
                      datalabels: {
                        anchor: 'end',
                        align: 'end',
                        display: 'auto',
                      }
                    },
                  }}
                  data={{
                    labels: estagios
                      .filter(
                        (estag1) =>
                          estag1.id !== "todos" &&
                          JSON.parse(datasets.estagios)
                            .map((estag2) => estag2[0])
                            .includes(estag1.id)
                      )
                      .sort((a, b) => (a.id > b.id ? 1 : b.id > a.id ? -1 : 0))
                      .map((estagio) => estagio.nome),
                    datasets: [
                      {
                        data: JSON.parse(datasets.estagios)
                          .sort((a, b) =>
                            a[0] > b[0] ? 1 : b[0] > a[0] ? -1 : 0
                          )
                          .map((estagio) => estagio[1]),
                        backgroundColor: [
                          "#D32F2F",
                          "#7B1FA2",
                          "#E65100",
                          "#C2185B",
                          "#512DA8",
                          "#388E3C",
                          "#303F9F",
                          "#1976D2",
                          "#5D4037",
                          "#0097A7",
                          "#00796B",
                          "#827717",
                          "#33691E",
                          "#FF6F00",
                          "#FFFF00",
                          "#DD2C00",
                          "#EEEEEE",
                        ],
                        hoverOffset: 4,
                      },
                    ],
                  }}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent={"space-between"} mt={4}>
              <Grid item lg={12}>
                <MaterialTable
                  title="Como Soube do Programa"
                  columns={[
                    { title: "Opção", field: "name" },
                    { title: "Quantidade", field: "qtd" },
                    { title: "Porcentual", field: "percent" },
                  ]}
                  data={JSON.parse(datasets.como_soube)
                    .sort((a, b) => a[0] - b[0])
                    .map((como) => ({
                      name: listaComoSoubePrograma.find((e) => e.id === como[0])
                        .label,
                      qtd: como[1],
                      percent:
                        Intl.NumberFormat("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(
                          (como[1] /
                            JSON.parse(datasets.como_soube)
                              .map((e) => e[1])
                              .reduce((a, c) => a + c)) *
                          100.0
                        ) + " %",
                    }))}
                  options={{
                    paging: false,
                    search: false,
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        )}
      </div>
    </Container>
  );
};

export default IndicadoresTecnicos;
